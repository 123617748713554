.consultation {
    &__infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 35px;
    }

    &__vote {
        column-gap: 7.5px;
    }

    &__details {
        display: flex;
        flex-wrap: wrap;
        column-gap: 60px;
        row-gap: 20px;
        margin-bottom: 15px;
    }

    &-detail {
        display: flex;
        align-items: center;
        column-gap: 10px;

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__icon {
            width: 40px;
            height: 40px;
            fill: $color-svg--consultations;
        }

        &__label {
            line-height: 1.15em;
            font-weight: 700;
            text-transform: uppercase;
        }

        // &__value { }
    }


    // Voting form
    &-survey {
        display: flex;
        flex-direction: column;

        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: none;
            border-radius: 0;
        }

        &__title {
            font-weight: 700;
            padding: 0;

            span {
                color: $color-flexible-alert;
            }
        }

        &__text {
            font-style: italic;
            color: $color-gray;
        }

        &__items {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            margin-top: 20px;
        }

        &__item {
            display: flex;
            align-items: center;
            column-gap: 7.5px;

            // input { }

            label {
                font-weight: 400;
                margin-top: 2px;
            }
        }

        &__button {
            margin: 0 0 0 auto;
        }

        .consultation_checkbox {
            flex: none;
        }
    }
}

.single-consultations {
    .breadcrumb ul li:not(:first-child,:nth-child(2)){
        pointer-events: none;
    }

    .proposition-wrapper {
        margin-top: 50px;
    }
}


//=================================================================================================
// ACF Forms
//=================================================================================================

form#prop_consultations .buttons-container {
    justify-content: flex-end;
}


//=================================================================================================
// Date passed (voting or proposal)
//=================================================================================================

.message {
    &-voting,
    &-held {
        a {
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .consultation__details {
        column-gap: 30px;
    }

}
