.header {
    @include position(fixed, $top: 0, $left: 0, $bottom: 0, $right:0);
    @include size(unset, fit-content);
    z-index: 15;
    transition: background-color $duration ease-in-out;

    &__container {
        @include flex($justifyContent: space-between);
        height: 130px;
        transition: height $duration ease-in-out;
    }

    &__link {
        @include flex($alignItems: center);

        svg {
            fill: $color-white;
            @include size(170px, 55px);
            margin-bottom: 5px;
        }
    }

    &__title {
        font-size: 0;
        line-height: 0;
        @include size(0);
    }

    &__content {
       @include flex($alignItems: center);
        gap: 60px;
        padding-top: 15px;
    }

    &--fixed {
        background-color: rgba($color-dark, 0.95);

        .header {
            &__container {
                height: 100px;
            }
        }

        .nav-main {
            .menu__link {
                &:before {
                    bottom: -30px;
                }
            }

            .submenu {
                top: 100px;
                border-top: 1px solid rgba(133, 180, 186, 0.30);
    
            }
        }

        &.darken {
            background-color: $color-dark;
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    @include flex($alignItems: center);
    gap: 10px;

    &__item {
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        @include size(40px);
        transition: all $duration ease-in-out;

        svg {
            @include size(100%);
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
        
        &:hover, &:focus {
            background-color: transparent;

            svg {
                fill: $color-main;
            }
        }
    }

    #accessconfig {
        max-height: 40px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        position: absolute;
        
        &__container {
            height: 110px;
        }

        &__link svg {
            @include size(140px, 45px);
        }

        &__content {
            gap: 24px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        &__container {
            height: 80px;
        }

        &__link svg {
            @include size(110px, 35px);
        }

        &__content {
            gap: 5px;
        }
    }

    .tools {
        gap: 0;
    }
}


// 640
@media screen and (max-width: $small) {

}
