//=================================================================================================
// Home
//=================================================================================================

// Usage : @extend %removeMargin;
%removeMargin {
    margin: 0!important;
    padding: 0!important;
}

// Usage : @extend %homeTitle;
%homeTitle {
    @extend %removeMargin;
    font-weight: 300;
    font-size: toRem(60);
    line-height: toRem(55);
    letter-spacing: 1.8px;
    text-transform: uppercase;
}

// Usage : @extend %homeSubTitle;
%homeSubTitle {
    font-weight: 900;
    font-size: toRem(14);
    line-height: toRem(20);
    letter-spacing: 4.2px;
    text-transform: uppercase;
    @include flex($alignItems: center);
    gap: 15px;

    svg {
        width: 61px;
        height: 8px;
    }
}

// Usage : @extend %homeButton;
%homeButton {
    @extend %removeMargin;
    height: 50px;
    background-color: transparent!important;
    border: 2px solid $color-main!important;
    position: relative;
    @include flex($justifyContent: space-between!important);

    &:before {
        @include position(absolute, $top: 0, $left: 0);
        @include size(0, 100%);
        content: "";
        background-color: $color-main;
        z-index: 0;
        transition: all $duration ease-in-out;
    }

    span {
        @include flex($alignItems: center, $justifyContent: center);
        @include size(50px);
        background-color: $color-main;
        z-index: 1;
        margin-left: -1px;

        svg {
            @include size(26px);
            fill: $color-white;
            transition: all $duration ease-in-out;
        }
    }

    p {
        font-weight: 900;
        font-size: toRem(14);
        letter-spacing: 0.28px;
        text-transform: uppercase;
        padding: 0 17px;
        z-index: 1;
    }

    &:hover, &:focus {
        &:before {
            width: 100%;
        }

        span svg {
            fill: $color-dark;
            transform: translateX(10px);
        }

        p {
            color: $color-dark;
        }
    }
}

// Usage : @extend %navigation;
%navigation {
    width: 50px!important;
    height: 50px!important;
    position: relative!important;
    top: unset!important;
    left: unset!important;
    right: unset!important;
    bottom: unset!important;
    margin-top: 0!important;
    background-color: transparent;
    border: 2px solid $color-main;
    transition: all $duration ease-in-out;
    cursor: pointer;
    z-index: 2!important;
    

    &:after {
        display: none;
    }

    svg {
        fill: $color-dark;
        @include size(100%);
        transition: all $duration ease-in-out;
    }

    &:hover, &:focus {
        background-color: $color-main;
    }
}


//=================================================================================================
// Clearfix
//=================================================================================================

// Usage : @extend %clearfix;
%clearfix {
    *zoom: 1;

    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


//=================================================================================================
// Visually hide an element
//=================================================================================================

// Usage : @extend %visuallyhidden
%visuallyhidden {
    width: 1px;
    height: 1px;
    position: absolute;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    white-space: nowrap;
}


//=================================================================================================
// Centered block
//=================================================================================================

// Usage : @extend %center
%center {
    display: block;
    margin-left: auto;
    margin-right: auto; 
}


//=================================================================================================
// Centered container
//=================================================================================================

// Usage : @extend %container
%container {
    max-width: $large;
    padding: 0 ($gl-gutter * 0.5);
    margin: 0 auto;
}


//=================================================================================================
// Cacul for positionning element left or right container
//=================================================================================================

// Usage : @extend %SpaceFull-to-Container--left
%SpaceFull-to-Container--left {
    left: calc((100% - ($large - $gl-gutter))/2);
}

// Usage : @extend %SpaceFull-to-Container--right
%SpaceFull-to-Container--right {
    right: calc((100% - ($large - $gl-gutter))/2);
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    %container {
        max-width: $medium;
        padding: 0 ($gl-gutter-md * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - ($medium - $gl-gutter-md))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - ($medium - $gl-gutter-md))/2);
    }
}


// 960
@media screen and (max-width: $medium) {

    %container {
        max-width: 90%;
        padding: 0 ($gl-gutter-sm * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - (90% - $gl-gutter-sm))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - (90% - $gl-gutter-sm))/2);
    }
}


// 640
@media screen and (max-width: $small) {
    %homeTitle {
        font-size: toRem(50);
        letter-spacing: 1.5px;
    }

    %homeSubTitle {
        letter-spacing: 2.1px;
        gap: 10px;
    }

    %container {
        padding: 0 ($gl-gutter-xs * 0.5);
        margin: 0 auto;
    }
    
    %SpaceFull-to-Container--left {
        left: calc((100% - (90% - $gl-gutter-xs))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - (90% - $gl-gutter-xs))/2);
    }
}
