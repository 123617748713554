.usefulPage {

    form {
        margin-bottom: 0;
    }

    &__wrapper {
        margin: 30px 0 0;
        padding: 25px 20px;
        background-color: $color-light;
        border-radius: $border-radius;
    }

    &__fields,
    &__answered {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            margin: 0;
        }
    }

    &__choices {
        display: flex;

        &.done {
            .usefulPage__answer {
                label {
                    cursor: auto;
                    pointer-events: none;
                }
            }

            &[data-answer="true"] {
                .usefulPage__answer {

                    &:not(.answerNo) {
                        label {
                            background: $color-gray;
                            border-color: $color-gray;
                            color: $color-white;

                            svg {
                                fill: $color-white;
                            }
                        }
                    }

                    &.answerNo {
                        label {
                            background: $color-white;
                            border-color: $color-white;
                            color: $color-gray;
                            opacity: 0.5;

                            svg {
                                fill: $color-gray;
                            }
                        }
                    }
                }
            }

            &[data-answer="false"] {
                .usefulPage__answer {

                    &:not(.answerNo) {
                        label {
                            background: $color-white;
                            border-color: $color-white;
                            color: $color-gray;
                            opacity: 0.5;

                            svg {
                                fill: $color-gray;
                            }
                        }
                    }

                    &.answerNo {
                        label {
                            background: $color-gray;
                            border-color: $color-gray;
                            color: $color-white;

                            svg {
                                fill: $color-white;
                            }
                        }
                    }

                }
            }
        }
    }

    &__answer {
        &:first-child {
            label {
                margin-right: 20px;
            }
        }

        label {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: $btn-spe-padding;
            background-color: $btn-empty-bg;
            border: $btn-border-width $btn-border-style $btn-empty-border-color;
            border-radius: $btn-spe-border-radius;
            font-size: $btn-spe-font-size;
            font-weight: $font-weight-bold;
            line-height: $btn-spe-line-height;
            color: $btn-empty-color;
            text-transform: $btn-text-transform;
            text-decoration: none;
            text-align: center;
            cursor: pointer;
            transition: {
                property: background-color, border-color, color;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                fill: $btn-empty-color;
                transition: fill $duration $timing;
            }

            &:hover,
            &:focus,
            &:active,
            &.is-active,
            &.is-checked {
                background-color: $btn-empty-bg--hover;
                border-color: $btn-empty-border-color--hover;
                color: $btn-empty-color--hover;

                svg {
                    fill: $btn-empty-color--hover;
                }
            }

            svg {
                width: 13px;
                height: 13px;
                margin: 0 10px 0 0;
            }
        }

        input {
            display: none;
        }
    }

    &__message {
        textarea {
            background: $color-white;
        }
    }

    &__mention {
        margin: 5px 0;

        a {
            text-decoration: underline;

            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .usefulPage {
        &__answered,
        &__fields {
            align-items: baseline;
            flex-direction: column;

            > .acf-label,
            > p {
                margin-bottom: 10px;
            }
        }
    }

}
