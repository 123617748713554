#franceConnectBtn,
#franceConnectLogout {
    width: 230px;
    height: 60px;
    background-image: url(/wp-content/themes/noyau/assets/images/franceconnect.svg);
    margin: 25px 0 0;
    border-radius: 2px;
    border: 0;
    background-repeat: no-repeat;
    background-size: cover;
}

#franceConnectBtn {
    &:hover {
        background-image: url(/wp-content/themes/noyau/assets/images/franceconnect-hover.svg);
    }
}

#franceConnectLogout {
    color: $color-white;
    background: #034EA2 none;
    border-radius: $border-radius;
    margin: 20px 0 0;

    &:hover,
    &:focus,
    &:active {
        background: $color-main;
        color: $color-white;
    }
}

.fc {
    display: flex;
    justify-content: center;
    text-align: center;
    background: $color-light;
    padding: 30px;
    border-radius: 10px;

    &__title,
    &__or,
    &__identity {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        line-height: $line-height--heading;
        font-size: $font-size--4;
        margin: 0 0 5px;
    }

    &__description{
        font-style: italic;
    }

    &__link {
        text-decoration: underline;

        &:hover,
        &:focus {
        text-decoration: none;
        }
    }

    &__or {
        margin: 30px auto;
        text-transform: uppercase;
        position: relative;
        width: 80px;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 50px;
            height: 1px;
            background: $color-gray;
            top: 50%;
            transform: translateY(-50%);
        }

        &::before {
            left: -50px;
        }

        &::after {
            right: -50px;
        }
    }

    &__identity {
        span {
            display: flex;
            justify-content: center;
            font-size: $font-size--text;
            font-family: $font-family;
            font-weight: $font-weight;
            line-height: $line-height;
            margin: 5px 0 0;
        }
    }
}

input[readonly="readonly"] {
    cursor: default;
    color: $color-gray;
}
