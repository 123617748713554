//======================================================================================================
// Breakpoints
//======================================================================================================
$large                                  : 1200px;
$medium                                 : 960px;
$small                                  : 640px;
$x-small                                : 320px;


//======================================================================================================
// Grids
//======================================================================================================
$gl-colCount                            : 12;
$gl-gridName                            : grid;
$gl-colName                             : col;
$gl-attributeName                       : class;
$gl-gutter                              : 30px;
$gl-gutter-vertical                     : 30px;

//Gutter responsive medium
$gl-gutter-md                           : 20px;
$gl-gutter-md-vertical                  : 20px;

//Gutter responsive small
$gl-gutter-sm                           : 20px;
$gl-gutter-sm-vertical                  : 20px;

//Gutter responsive x-small
$gl-gutter-xs                           : 20px;
$gl-gutter-xs-vertical                  : 20px;

$gl-mq-width                            : 'max-width';
$gl-mq-list: (
        lg: $large,
        md: $medium,
        sm: $small,
        xs: $x-small,
);


//======================================================================================================
// Global Colors
//======================================================================================================

// Colors
$red                                    : #C92C2C;
$redlight                               : #FFE6E6;
$orange                                 : #E8810B;
$orangelight                            : #fae6ce;
$green                                  : #46B450;
$greenlight                             : #E2FFEF;

// Main Colors
$color-main                             : #E77D5F;
$color-second                           : #85B4BA;
$color-third                            : #B6C866;
$color-fourth                           : #25808C;

// Black & White
$color-white                            : #FFFFFF;
$color-light                            : #E9F0F1;
$color-gray                             : #6F787A;
$color-dark                             : #1E2526;
$color-black                            : #000000;

// RGBA
$color-main--rgba                       : rgba($color-main, 0.8);
$color-second--rgba                     : rgba($color-second, 0.8);
$color-third--rgba                      : rgba($color-third, 0.8);
$color-black--rgba                      : rgba($color-black, 0.9);
$color-overlay--rgba                    : rgba($color-black, 0.8); // on overlay accessconfig

// Bloc infos
$color-error                            : $red;
$color-alert                            : $orange;
$color-success                          : $green;
$color-bg-error                         : $redlight;
$color-bg-alert                         : $orangelight;
$color-bg-success                       : $greenlight;
$color-flexible-alert                   : #E20000; // This color is only used on bloc-content--alert
 
// Text
$color-text                             : $color-dark;
$color-link                             : $color-fourth;
$color-link--hover                      : $color-dark;
$color-highlight                        : #FFEEA8;

// Background
$color-bg--neutral                      : $color-white;
$color-bg--body                         : $color-white;
$color-bg--transparent                  : transparent;
$color-bg--image                        : rgba($color-main, 0.2);
$color-bg--icons                        : $color-white;

// SVG
$color-svg--events                      : $color-main ; // single events
$color-svg--maps                        : $color-main ; // popup maps

// Single Date publication (post & jobs & adverts)
$color-date--single                     : $color-gray;


//======================================================================================================
// Typography
//======================================================================================================

// Font Weight
$font-weight-thin                       : 200;
$font-weight-light                      : 300;
$font-weight-normal                     : 400;
$font-weight-medium                     : 500;
$font-weight-semibold                   : 600;
$font-weight-bold                       : 700;
$font-weight-extrabold                  : 800;
$font-weight-black                      : 900;

// Body
$font-size                              : 16px;
$font-style                             : normal;
$font-variant                           : normal;
$font-family                            : "Urbanist", sans-serif;
$font-weight                            : $font-weight-normal;
$line-height                            : 1.5;

// Font Size
$font-size--text-medium                 : toRem(20); // 20px
$font-size--text                        : toRem(16); // 16px
$font-size--text-small                  : toRem(14); // 14px
$font-size--text-xsmall                 : toRem(13); // 13px

// Headings
$font-size--1                           : toRem(50); // 50px
$font-size--2                           : toRem(34); // 34px
$font-size--3                           : toRem(28); // 28px
$font-size--4                           : toRem(22); // 22px
$font-size--5                           : toRem(18); // 18px
$font-size--6                           : toRem(16); // 16px

$font-family--heading                   : "Urbanist", sans-serif;
$font-weight--heading                   : $font-weight-bold;
$line-height--heading                   : 1.2;


//======================================================================================================
// Borders
//======================================================================================================
$border-width                           : 1px;
$border-width--blockquote               : 16px;
$border-style                           : solid;
$border-color                           : $color-dark;
$border-radius                          : 0;
$border-radius--round                   : 0;
$borders                                : $border-width $border-style $border-color;
$borders--blockquote                    : $border-width--blockquote $border-style $border-color;


//======================================================================================================
// Box-shadow
//======================================================================================================
$shadow                                 : 1px 1px 10px rgba(34, 41, 43, 0.08), 0px 0px 1px rgba(34, 41, 43, 0.08);
$shadow--hover                          : 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);


//======================================================================================================
// Transitions
//======================================================================================================
$duration                               : .35s;
$timing                                 : ease-in-out;
// if need
// $delay                                  : .5s;


//======================================================================================================
// Image URL
//======================================================================================================
// A décommenter si besoin :
$urlShort : '/wp-content/themes/auterive/assets/src/images/sprite-images/base-icons/';


//====================================================================================================== 
// Buttons
//======================================================================================================

// Font
$btn-font-family                        : $font-family;
$btn-font-weight                        : 700;
$btn-font-size                          : $font-size--text-small;
$btn-line-height                        : 1.2;
$btn-text-transform                     : uppercase;

// Colors
$btn-color                              : $color-dark;
$btn-color--hover                       : $color-white;

// Backgrounds
$btn-bg                                 : $color-main;
$btn-bg--hover                          : $color-dark;

// Borders
$btn-border-width                       : 1px;
$btn-border-style                       : solid;
$btn-border-radius                      : 0;
$btn-border-color                       : $color-main;
$btn-border-color--hover                : $color-dark;

// Padding
$btn-padding                            : 15px 30px;


    //===============================================
    // Button secondary (used on document flexible)
    //===============================================

    // Colors
    $btn-second-color                   : $color-dark;
    $btn-second-color--hover            : $color-dark;

    // Backgrounds
    $btn-second-bg                      : $color-bg--transparent;
    $btn-second-bg--hover               : $color-second;

    // Borders
    $btn-second-border-color            : $color-second;
    $btn-second-border-color--hover     : $color-second;

    // Padding
    $btn-second-padding                 : $btn-padding;


    //===============================================
    // Button variant 1 (if you need)
    //===============================================

    // Colors
    $btn-variant-color                  : $color-main;
    $btn-variant-color--hover           : $color-white;

    // Backgrounds
    $btn-variant-bg                     : $color-bg--neutral;
    $btn-variant-bg--hover              : $color-bg--transparent;

    // Borders 
    $btn-variant-border-color           : $btn-variant-bg;
    $btn-variant-border-color--hover    : $color-white;


    //======================================================================================================
    // Buttons specific (tag & empty)
    //======================================================================================================

    // Font
    $btn-spe-font-weight                : $font-weight;
    $btn-spe-font-size                  : $font-size--text-xsmall;
    $btn-spe-line-height                : 1.5;

    // Padding
    $btn-spe-padding                    : 7px 15px;
 
    // Borders
    $btn-spe-border-radius              : 0;


        //===============================================
        // Button empty (button for filter or reset)
        //===============================================

        // Colors
        $btn-empty-color                    : $color-dark;
        $btn-empty-color--hover             : $color-white;

        // Backgrounds
        $btn-empty-bg                       : $color-bg--transparent;
        $btn-empty-bg--hover                : $color-dark;

        // Borders
        $btn-empty-border-color             : $color-dark;
        $btn-empty-border-color--hover      : $color-dark;


        //===============================================
        // Button tag (button bottom page (transition or tag actu))
        //===============================================

        // Colors
        $btn-tag-color                      : $color-dark;
        $btn-tag-color--hover               : $color-white;

        // Backgrounds
        $btn-tag-bg                         : $color-second;
        $btn-tag-bg--hover                  : $color-dark;

        // Borders
        $btn-tag-border-color               : $color-second;
        $btn-tag-border-color--hover        : $color-dark ;


        //===============================================
        // Button Privacy Policy
        //===============================================

        // Colors
        $btn-privacy-color                      : $color-text;
        $btn-privacy-color--hover               : $color-white;

        // Backgrounds
        $btn-privacy-bg                         : $color-white;
        $btn-privacy-bg--hover                  : $color-bg--transparent;

        // Borders
        $btn-privacy-border-color               : $btn-privacy-bg;
        $btn-privacy-border-color--hover        : $btn-privacy-bg;


//===============================================
// Button link-document (and contact details)
//===============================================

$btn-action-size                   : 30px; // Width & height

// Colors
$btn-action-color                  : $color-text;
$btn-action-color--hover           : $color-text;

// Backgrounds
$btn-action-bg                     : transparent;
$btn-action-bg--hover              : transparent;

// Borders
$btn-action-width                  : 0;
$btn-action-style                  : $border-style;
$btn-action-border-color           : transparent; 
$btn-action-border-color--hover    : transparent; 
$btn-action-border-radius          : $border-radius--round;


//======================================================================================================
// Forms
//======================================================================================================
$forms                                  : ('[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea');
$btn                                    : ('.button, button, [type=submit], [type=reset], [type=button]');
$input-bg                               : $color-bg--transparent;
$placeholder                            : darken($color-main, 20%);
$form-border-color                      : $color-dark;
$form-border-color--focus               : $color-dark;
$form-border                            : $border-width $border-style $form-border-color;
$form-color-icon                        : $color-dark; // Couleur des icones dans les formulaires proposition events


//======================================================================================================
// Search input (for popup, search page and 404)
//======================================================================================================
$search-input-height                    : 68px;
$search-input-bg                        : $color-white;
$search-input-border-color              : $color-white;
// 404 border color 
$search-input-border-color--404         : $color-dark;

// button search loop
$search-input-button-width              : 70px;
$search-input-button-height             : 100%;
$search-input-button-border             : 0 $border-style $form-border-color;
$search-input-button-bg                 : $color-main;
$search-input-button-color              : $color-dark;

// Active / focus
$search-input-border-color--active      : $color-dark;
// button search loop
$search-input-button-bg--active         : $color-third;
$search-input-button-color--active      : $color-dark;
$search-input-button-border-color--focus: $color-dark;


//======================================================================================================
// Tables
//======================================================================================================
$stripes                                : #f8f8f8;
$caption                                : #ababab;

// Flexible table
$table-bg                               : $color-white;
$table-color                            : $color-text;
$table-border-width                     : 1px;
$table-border-color                     : $color-dark;
$table-even-bg                          : $color-white; // Pair background-color
$table-even-color                       : $color-text; // Pair color
$table-odd-bg                           : $color-light; // Impair background-color
$table-odd-color                        : $color-text; // Impair color
$table-head-bg                          : $color-dark;
$table-head-color                       : $color-white;
$table-head-border-color                : $color-white;


//======================================================================================================
// Code
//======================================================================================================
$code-color                             : $color-text;
$code-size                              : $font-size--text-small;
$code-family                            : Menlo, monospace;
$code-bg                                : $color-bg--transparent;


//======================================================================================================
// Card
//======================================================================================================
// Padding général des cards
$card--padding                          : 0;

// Si le padding général est égal à 0
$card-content--padding                  : 20px; // 20px si le padding général est égal à 0
$card-categorie--margin                 : $card--padding;
$card-event-v1-gap                      : 0; // 0 si le padding général est égal à 0

// Height de l'image des cards
$card-imageH                            : 200px; // 200px si le padding général est égal à 0
$card-imageH-Chronology-RWD             : 160px; // 160px si le padding général est égal à 0

// Background Card
$color-bg--card                         : $color-white;
$color-bg-neutral--card                 : $color-bg--neutral;
$color-bg--date-v1                      : $color-main;
$color-bg--date-v2                      : $color-main;

// Text Card
$color--card                            : $color-text;
$color-second--card                     : $color-dark; // Date publication & location card
$color--date                            : $color-dark;


//======================================================================================================
// Taxo (card + listing + reports) 
//======================================================================================================
// Font
$taxo-font-family                        : $font-family;
$taxo-font-size                          : $font-size--text-xsmall;
$taxo-font-weight                        : $font-weight;
$taxo-line-height                        : 1.2;
$taxo-text-transform                     : uppercase;

// Color
$taxo-color                              : $color-text;

// Background
$taxo-bg                                 : $color-bg--neutral;

// Borders
$taxo-border-width                       : 0;
$taxo-border-style                       : $border-style;
$taxo-border-color                       : $taxo-bg;
$taxo-border-radius                      : 0;

// Padding
$taxo-padding                            : 5px 15px;


//======================================================================================================
// Image Caption
//======================================================================================================

// Background Caption
$color-bg-caption                       : $color-main--rgba;

// Text Caption
$color-caption                          : $color-dark;


//======================================================================================================
// Swiper + Glightbox navigation
//======================================================================================================
 
// Arrow
$arrow-size                             : 35px; // width + height
$arrow-border-radius                    : 0; 
$arrow-bg                               : transparent;
$arrow-bg--hover                        : $color-main;
$arrow-color                            : $color-dark;
$arrow-color--hover                     : $color-dark;


// Cross on Glightbox
$cross-size                             : 35px; // width + height
$cross-border-radius                    : 0;
$cross-bg                               : $color-third;
$cross-bg--hover                        : $color-dark;
$cross-color                            : $color-dark;
$cross-color--hover                     : $color-white;


//======================================================================================================
// Accessibility a42
//======================================================================================================
$a42-background                         : $color-white;
$a42-border                             : 0;
$a42-border-radius                      : 0;
 
$a42-card-background                    : $color-fourth;
$a42-card-border                        : $color-third;
$a42-card-border-radius                 : 0;
$a42-card-input-background              : $color-white;
$a42-card-input-border                  : $color-main;
$a42-card-input-active                  : $color-main;

$a42-cross                              : $color-dark;
$a42-cross-thickness                    : 6px;


//======================================================================================================
// Filters
//======================================================================================================

// Filter bloc
$filter-bg                              : $color-bg--neutral;

//===============================================
// Button toggler
//===============================================
// Colors
$btn-toggler-color                      : $color-dark;
$btn-toggler-color--hover               : $color-white;

// Backgrounds
$btn-toggler-bg                         : transparent;
$btn-toggler-bg--hover                  : $color-dark;

// Borders
$btn-toggler-border-color               : $color-dark;
$btn-toggler-border-color--hover        : $color-dark;


//===============================================
// Filter input
//===============================================
$f-input-font-size                      : $font-size--text-small;
$f-input-color                          : $color-text; 
$f-input-bg                             : $color-bg--transparent;
$f-input-border-width                   : 1px;
$f-input-border-radius                  : $border-radius;
$f-input-border-color                   : $color-dark;
$f-input-icon                           : $color-dark;
// Container subcategory
$f-input-container-bg                   : $color-light;

// Active filter
$f-input-color--active                  : $color-dark;
$f-input-bg--active                     : $color-bg--transparent;
$f-input-border-color--active           : $color-main;
$f-input-icon--active                   : $color-dark;

// Selected filter
$f-input-color--selected                : $color-dark;
$f-input-bg--selected                   : $color-bg--transparent;
$f-input-border-color--selected         : $color-main;
$f-input-icon--selected                 : $color-dark;

//===============================================
// Sub Filter input 
//===============================================
$f-sub-input-color                      : $color-text;
$f-sub-input-bg                         : $color-white;
$f-sub-input-border-width               : 0;
$f-sub-input-border-radius              : $border-radius;
$f-sub-input-border-color               : $color-white;

// Sub filter checked
$f-sub-input-color--checked             : $color-dark; 
$f-sub-input-bg--checked                : $color-second;
$f-sub-input-border-color--checked      : $color-second;


//===============================================
// Filter input Keyword + Button Close
//===============================================
// Input
$f-inputKeyword-border-color            : $f-input-border-color;

// Active filter
$f-inputKeyword-border-color--active    : $color-dark;

// Selected filter
$f-inputKeyword-border-color--selected  : $color-dark;


// Button Close
//===============================================
$f-Keyword-close-border-width           : 1px;

// Colors
$f-Keyword-color                        : $color-white;
$f-Keyword-color--hover                 : $color-dark;

// Backgrounds
$f-Keyword-bg                           : $color-dark;
$f-Keyword-bg--hover                    : $color-bg--transparent;

// Borders
$f-Keyword-border-color                 : $f-Keyword-bg;
$f-Keyword-border-color--hover          : $f-Keyword-bg;
