.wrapper {
    #comarquage {

        .co-btn.co-btn-slide {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 95%;
            margin: 0 30px;
            padding: 20px 0;
            border-radius: 0;
            color: $color-text;
            font-family: $font-family;
            white-space: normal;
            
            &.active {
                border-color: $color-gray;
            }

            .chevron-down, .co-external-link {
                position: relative;
                right: auto;
                top: auto;
                padding: 5px;
                border-radius: $border-radius;
                background: $color-white;
                line-height: initial;
            }
        }
        
        #co-page {

            &.noeud {
                margin-top: 15px;
            }

            .co-organisme-info .co-org-maps {
                z-index: 1;
            }

            .co-content {
                margin-top: 20px;
            }

            .co-breadcrumb {
                margin-bottom: 40px;
                padding: 0;
                font-size: $font-size--text;
            }

            .news-cat {
                margin: 0 0 15px;

                span {
                    background-color: $color-bg--neutral;
                    color: $color-text;
                    font-size: $font-size--text-xsmall;
                    text-transform: uppercase;
                }
            }

            .date {
                color: $color-gray;
                font-size: $font-size--text-small;
            }

            .tool-slide {
                margin-bottom: 20px;

                button {
                color: $color-text;
                font-size: $font-size--text-small;
                font-weight: $font-weight-bold;
                text-transform: uppercase;

                    > div {
                        margin-left: 5px;
                        padding: 5px;
                        border-radius: $border-radius;
                    }

                    svg {
                        width: 15px;
                        height: 15px;
                        margin-top: 0;
                        fill: $color-white;
                    }
                }
            }

            .co-annexe {
                font-size: $font-size--text;
                margin-top: 40px;

                .fiche-bloc, .bloc-ficheliee {
                    margin-bottom: 40px;
                    padding: 30px;
                    background-color: rgba($color-main, 0.2);
                    border: 0;

                    &.fiche-slide {
                        padding: 0;
                        background-color: $color-white;
                        border: 1px solid;

                        .fiche-item .fiche-item-title {
                            h2, h3 {
                                span {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    .fiche-item-content {
                        padding: 0;
                    }
                }

                .bloc-ficheliee {
                    .co-panel-heading {
                        margin: 0;
                        padding: 0 0 15px 0;
                    }
                }
            }

            .panel-comment, .panel-source {
                margin-left: 5px !important;
                font-size: 0.75rem; // 12px
                color: $color-gray;
            }

            .fiche-slide {
                .fiche-item-content {
                    padding: 30px !important;
                    border: 0;
                }
            }

            .fiche-bloc {
                border-color: $color-text;
                border-radius: $border-radius;

                .fiche-item {
                    border-color: $color-text;

                    .fiche-item-title {
                        h2, h3 {
                            padding: 0;
                            border: 0;

                            span {
                                margin-bottom: 25px;
                                padding: 0;
                                font-family: $font-family--heading;
                                font-weight: $font-weight--heading;
                            }
                        }
    
                        h2 span {
                            font-size: 1.375rem;
                        }
    
                        h3 span {
                            font-size: $font-size--4;
                        }
                    } 

                    &.fiche-slide {
                        .fiche-item-title {
                            h2, h3 {
                                span {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                }    

                .panel-sat .list-arrow {
                    li {
                        margin: 10px 0;
                        padding: 0 0 0 20px;

                        .panel-link {
                            a {
                                display: flex;
                                align-items: center;

                                svg {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                } 
    
            }


            // Table
            .table {
                margin: 20px 0;
                border: 1px solid $color-main;

                tr:nth-child(odd) td,
                caption {
                    background-color: $color-bg--neutral; 
                }

                caption {
                    background-color: $color-main;
                    border: 1px solid $color-main;
                    border-top: 0;
                }

                tr td {
                    padding: 15px;
                    border-color: $color-main;
                }
            }

            .co-content .table p {
                margin-bottom: 0;
            }

            .bloc-ousadresser {
                margin: 20px 0;
                h3 {
                    margin: 0 0 10px;
                }

                .fiche-bloc {
                    margin-left: 0;
                    margin-right: 0;

                    h3 > a {
                        display: flex;
                        width: 100%;
                        margin: 0;
                        padding: 10px;
                        text-transform: uppercase;
                        font-size: $font-size--text-small;
                        font-weight: $font-weight-bold;
                        transition: {
                            property: background-color, border-color, color;
                            duration: $duration;
                            timing-function: $timing;
                        }

                        .co-external-link {
                            padding: 0;
                            background-color: $color-bg--transparent;
                            line-height: 0;
                        }                

                        &:hover, &:focus {
                            background-color: $color-text;
                            border-color: $color-text;
                            color: $color-white;
                        }
                    }
                    
                    .organisme-header {
                        display: block;
                        width: 100%;

                        tbody {
                            display: block;
                            width: 100%;
                            
                            tr {
                                display: flex;
                                align-items: stretch;
                                flex-wrap: wrap;
                                width: 100%;

                                td {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .wrapper {

        #comarquage {

            .co-btn.co-btn-slide {
                width: 90%;
            }

        }
    }

}


// 640
@media screen and (max-width: $small) {

    .wrapper {

        #comarquage {

            .co-btn.co-btn-slide {
                width: 100%;
                margin: 0;
                padding: 20px;
            }

            #co-page {
                
                .fiche-slide .fiche-item-content {
                    overflow: hidden;
                    padding: 20px!important;
                }

                .fiche-bloc .fiche-item .fiche-item-title h2 span {
                    font-size: 1.500rem; // 24px
                }

                .panel-sat .list-arrow li .panel-link a {
                    display: block;
                }

                .bloc-ousadresser {
                    .fiche-bloc {
                        .organisme-header {
                            tbody {
                                tr {
                                    td {
                                        display: block;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }

}
