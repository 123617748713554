.cover {
    position: relative;
    background-color: $color-light;
    padding: 50px 0 70px;
    margin-top: 100px;

    &__container {
        display: flex;
        gap: 50px;
        height: auto;
    }

    &__image-wrapper {
        @include size(600px, 100%);
        min-height: 340px;
        max-height: 340px;
        overflow: hidden;
        position: relative;

        img {
            @include size(100%);
            object-fit: cover;
            aspect-ratio: 600 / 340;
            min-height: 340px;
        }
    }

    &__informations {
        flex: 1;
        flex-shrink: 1;
        @include flex($direction: column);
        gap: 10px;
        margin: auto 0;
    }

    &__title {
        font-size: toRem(44);
        font-weight: 600;
        line-height: toRem(42);
        letter-spacing: 0.88px;
        margin: 0;
    }

    svg {
        @include size(80px, 10px);
        fill: $color-second;
    }

    &__intro {
        font-size: toRem(18);
        font-weight: 400;
        line-height: toRem(25);
        letter-spacing: 0.36px;
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__image-wrapper {
            min-width: 490px;
            width: 490px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        padding: 45px 0 70px;

        &__container {
            flex-direction: column;
            gap: 30px;
        }

        &__image-wrapper {
            min-width: 100%;
            width: 100%;
            height: 350px;
        }

        &__title {
            font-size: toRem(40);
            line-height: toRem(42);
        }

        &__intro {
            font-size: toRem(18);
            line-height: toRem(22);
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__image-wrapper {
            height: 180px;
            max-height: 180px!important;
            min-height: 180px!important;

            img {
                max-height: 180px!important;
                min-height: 180px!important;
            }
        }
        
        &__title {
            font-size: toRem(34);
            line-height: toRem(38);
        }
    }
}
