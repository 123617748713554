.breadcrumb {
    &__container {
        width: fit-content;
        background-color: $color-white;
        position: relative;
        padding: 10px 20px;
        z-index: 1;
        margin-top: -25px;
        margin-bottom: 60px;
    }

    ul  {
        @include flex($wrap: wrap);
        gap: 5px 30px;
    }

    li {
        @include flex($alignItems: center);
        color: $color-dark;
        position: relative;
        font-weight: 400;
        font-size: toRem(14);
        line-height: toRem(20);
        letter-spacing: 0.28px;

        &:not(:last-child) {
            &:before {
                position: absolute;
                content: "";
                mask-image: url(/wp-content/themes/auterive/assets/src/images/sprite-images/base-icons/ic-nav-chevron-bottom.svg);
                background-color: $color-dark;
                mask-repeat: no-repeat;
                top: unset;
                right: -25px;
                width: 22px;
                height: 22px;
                -webkit-mask-size: 22px;
                mask-size: 22px;
                transform: rotate(-90deg);
            }
        }

        &:first-child {
            padding-left: 0;
        }

        a {
            transition: all $duration ease-in-out;

            &:hover {
                color: $color-link;
                text-decoration: underline;
            }
        }
    }

}
