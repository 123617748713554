//======================================================================================================
// Bloc Chronology
//======================================================================================================
.bloc-chronology {
    @include flex($justifyContent: space-between);
    position: relative;

    &.grid {
        margin-top: 30px;
    }

    &::before {
        content: "";
        @include absolute($top: 200px, $left: calc(50% - 1px));
        display: block;
        @include size(1px, calc(100% - 400px));
        background-color: $color-main;
    }

    // With media --------------------------------------------------------------------------------------
    .card {
        &--chronology {
            height: 100%;
            position: relative;

            &::after {
                content: '';
                @include absolute($top: calc(50% - 13px), $left: calc(1200px - 364px * 2));
                display: block;
                @include size(26px);
                background-color: $color-main;
                border-radius: $btn-spe-border-radius;
            }

            &:nth-child(even) {
                margin-top: 120px;
                margin-right: 115px;

                &:nth-child(2) {
                    margin-top: 260px;
                }

                &::after {
                    right: calc(1200px - 364px * 2);
                    left: inherit;
                }
            }

            &:nth-child(odd) {
                margin-left: 115px;

                &:not(:first-child) {
                    margin-top: -140px;
                }
            }
        }

        &__image-wrapper {
            img {
                object-position: top;
            }

            iframe {
                max-width: 100%;
                max-height: 100%;
            }

            video {
                margin: 0;
                max-height: calc(100% + 6px);
            }
        }

        &__container {
            height: 380px;
        }

        &__content {
            height: calc(100% - $card-imageH);
            justify-content: center;
        }

        &__title {
            font-size: $font-size--3;

            &:after {
                display: none;
            }
        }

        &__subtitle {
            font-family: $font-family--heading;
            font-size: $font-size--text-medium ;
        }

        &__label-transcription {
            margin-top: 20px;
            text-decoration: underline;
            cursor: pointer;
        }

        &__transcription {
            @include fixed($top: 50%, $left: 50%);
            z-index: 9;
            @include flex($direction: column, $alignItems: baseline);
            width: 970px;
            min-height: 250px;
            max-height: 700px;
            padding: 40px;
            overflow: auto;
            background-color: $color-white;
            opacity: 0;
            visibility: hidden;
            text-align: left;
            transform: translate(-50%, -50%);
            transition: opacity $duration $timing;

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }

        }

        &__close-popup {
            @include flex($alignItems: center, $justifyContent: center);
            @include absolute($top: 5px, $right: 5px);
            @include size(26px);
            border-radius: $btn-spe-border-radius;
            background-color: $color-third;
            cursor: pointer;
            transition: background-color $duration $timing;

            &:hover, &:focus {
                background-color: $color-main;

                &::before {
                    color: $color-white;
                }
            }

            &::before {
                content: '\f335';
                color: $color-gray;
                font: normal toRem(20)/1 'dashicons';
            }
        }

        &__transcription-title {
            margin-bottom: 20px;
            font-weight: $font-weight--heading;
        }

        &__mask-popup {
            @include fixed($top: 0, $left: 0);
            z-index: 2;
            @include size(100vw, 100vh);
            background-color: rgba($color-black, 0.5);
            visibility: hidden;
            opacity: 0;
            transition: opacity $duration $timing;

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    // Without media -----------------------------------------------------------------------------------
    &--without_media {
        &::before {
            height: calc(100% - 20px);
            top: 20px;
        }
    }

    &__container {
        display: flex;
        @include size(100%, auto);
        padding: 0 30px;

        &:nth-child(odd) {
            text-align: right;
            position: relative;

            .bloc-chronology__title,
            .bloc-chronology__subtitle,
            .bloc-chronology__description {
                padding-right: calc(50% + 85px);
            }
        }

        &:nth-child(even) {
            .bloc-chronology__title,
            .bloc-chronology__subtitle,
            .bloc-chronology__description {
                padding-left: calc(50% + 85px);
            }
        }
    }

    &__content {
        width: 100%;
        min-height: 170px;
    }

    &__title {
        position: relative;
        margin-top: 0;
        margin-bottom: 10px;
        font-size: $font-size--3;

        &--only_year {
            font-size: $font-size--1;
        }

        &::before {
            content: "";
            display: block;
            @include absolute($top: calc(50% - 13px), $left: calc(50% - 13px));
            @include size(26px);
            background-color: $color-main;
            border-radius: $btn-spe-border-radius;
        }

        &:after {
            display: none;
        }
    }

    &__subtitle {
        font-family: $font-family--heading;
        font-size: $font-size--text-medium;
        margin-bottom: 10px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .bloc-chronology {
        .card {
            &--chronology {
                &::after {
                    left: calc(960px - 247px * 2);
                }

                &:nth-child(even) {
                    flex-basis: 380px;
                    max-width: 380px;
                    margin-top: 120px;
                    margin-right: 0;

                    &:nth-child(2) {
                        margin-top: 260px;
                    }

                    &::after {
                        right: calc(960px - 247px * 2);
                    }
                }

                &:nth-child(odd) {
                    flex-basis: 380px;
                    max-width: 380px;
                    margin-left: 0;

                    &:not(:first-child) {
                        margin-top: -140px;
                    }
                }
            }

            &__transcription {
                width: 780px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .bloc-chronology {
        align-items: center;

        &.grid {
            flex-direction: column;
        }

        &--with_media {
            padding-left: 80px;

            &::before {
                top: 160px;
                left: 50%;
                height: calc(100% - 400px);
                transform: translate(-209px);
            }
        }

        .card {
            &--chronology {
                margin-top: 80px;

                &::after {
                    left: -71px;
                    top: calc(50% - 59px);
                }

                &:nth-child(even) {
                    margin-top: 80px;

                    &::after {
                        right: inherit;
                        left: -71px;
                    }
                }

                &:nth-child(odd) {
                    margin-top: 80px;

                    &:not(:first-child) {
                        margin-top: 80px;
                    }
                }

                &:nth-child(2n):nth-child(2) {
                    margin-top: 80px;
                }

                &:first-child {
                    margin-top: 0;
                }
            }

            &__transcription {
                width: 80%;
            }
        }

        &--without_media {
            &::before {
                height: 100%;
                left: 42px;
                top: 20px;
                transform: none;
            }
        }

        .bloc-chronology__container {
            margin-top: 80px;
            padding: 0;

            &:nth-child(odd) {
                text-align: left;

                .bloc-chronology__description,
                .bloc-chronology__subtitle,
                .bloc-chronology__title {
                    padding-right: 0;
                    padding-left: 85px;
                }
            }

            &:nth-child(2n) {
                .bloc-chronology__description,
                .bloc-chronology__subtitle,
                .bloc-chronology__title {
                    padding-left: 85px;
                }
            }

            &:first-child {
                margin-top: 0;
            }

            .bloc-chronology__title:before {
                left: 30px;
            }

            .bloc-chronology__content {
                min-height: inherit;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .bloc-chronology {

        .card--chronology {
            &::after {
                left: calc(50% - 13px);
                top: -46px;
            }

            &:nth-child(even) {
                flex-basis: 320px;
                max-width: 320px;
                margin-right: 0;

                &::after {
                    left: calc(50% - 13px);
                    top: -46px;
                }
            }

            &:nth-child(odd) {
                flex-basis: 320px;
                max-width: 320px;
            }

            &:first-child {
                margin-top: 50px;
            }
        }
        
        .card__container {
            height: 390px;
        }

        .card__image-wrapper {
            height: $card-imageH-Chronology-RWD;
        }

        .bloc-chronology__container {
            padding: 0;

            &:nth-child(odd) {
                text-align: left;

                .bloc-chronology__description,
                .bloc-chronology__subtitle,
                .bloc-chronology__title {
                    padding-right: 0;
                    padding-left: 40px;
                }
            }

            &:nth-child(2n) {
                .bloc-chronology__description,
                .bloc-chronology__subtitle,
                .bloc-chronology__title {
                    padding-left: 40px;
                }
            }

            .bloc-chronology__title:before {
                left: 0;
            }
        }

        &--without_media::before {
            height: 100%;
            background: linear-gradient(135deg, $color-main calc(100% - 170px), $color-bg--transparent calc(100% - 30px / 2));
            height: calc(100% - 20px);
            left: 12px;
            top: 20px;
        }

        .card__transcription {
            padding: 20px 30px 20px 20px;
        }
        
        &--with_media {
            padding-left: 0;

            &::before {
                top: 10px;
                height: calc(100% - 50px);
                transform: none;
            }
        }

    }

}
