@use "sass:list"; // https://sass-lang.com/documentation/breaking-changes/slash-div

//=================================================================================================
// HTML base
//=================================================================================================
html {
    font: $font-style $font-variant $font-weight list.slash(#{$font-size}, #{$line-height}) $font-family;
    font-size: $font-size;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // If need https://caniuse.com/?search=scroll-behavior%3A%20smooth
    // scroll-behavior: smooth;
}

//=================================================================================================
// Body base
//================================================================================================= 
body {
    font-size: $font-size--text;
    color: $color-text;
    background: $color-bg--body;
    letter-spacing: 0.32px; 

    &:not(.home) {
        .header {
            background-color: $color-dark;

            &__container {
                height: 100px;
            }
        }

        .nav-main {
            .menu__link::before {
                bottom: -30px;
            }
            
            .submenu {
                top: 100px;
                border-top: 1px solid rgba(133,180,186,.3);
            }
        }

        h2, h3, h4 {
            position: relative;

            &:after {
                @include position(absolute, $left: 0, $bottom: -8px);
                @include size(100px, 2px);
                content: "";
                background-color: $color-main;
                z-index: 0;
            }
        }

        h3::after {
            width: 60px;
        }

        h4::after {
            width: 30px;
        }
    }

    &.search {
        .searchInput {
            color: $color-dark;
            z-index: 1;
            border: 0px;
            background-color: rgba($color-main, 0.2)!important;
            
            &::placeholder {
                color: $color-dark;
            }
        }
    
        .search-svg {
            z-index: 1;

            &:hover, &:focus {
                background-color: $color-dark;
                
                &:before {
                    background-color: $color-white;
                }
            }
        }
    
        .searchResult {
            border: 1px solid rgba($color-main, 0.2);
            top: 0px;
        }
    }
    
    section[data-cpt="maps"] {
        .breadcrumb__container {
            margin-bottom: -15px;
        }
    }
}

ol,
ul {
    list-style-type: none;
}

p,
ol,
ul,
dl,
table {
    margin: 0 0 0 0;
    padding: 0;
}


//=================================================================================================
// Remove extra margin for nested lists
//================================================================================================= 
ul li ul {
    margin-bottom: 0;
}

ol li ol {
    margin-bottom: 0;
}


//=================================================================================================
// Headings
//================================================================================================= 
h1,
h2,
h3,
h4,
h5 {
    font-family: $font-family--heading;
    font-weight: 700;
    line-height: $line-height--heading;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin: 25px 0 10px;
}

.wysiwyg h2 {
    margin: 0px 0px 30px;
    padding: 5px 0;
}


//=================================================================================================
// Heading individual styles
//================================================================================================= 
h1 {
    font-size: $font-size--1;
}

h2,
.gform_wrapper form h2.gsection_title,
.title-newsletter-h2 {
    font-size: toRem(34);
    line-height: toRem(40);
    letter-spacing: 0.68px;
    margin-bottom: 10px;
}

h3 {
    font-size: toRem(28);
    line-height: toRem(35);
    letter-spacing: 0.56px;
}

h4 {
    font-size: toRem(20);
    line-height: toRem(25);
    letter-spacing: 0.4px;
}

h5 {
    font-size: $font-size--5;
}

h6 {
    font-size: $font-size--6;
}


//=================================================================================================
// Pictures styling
//=================================================================================================
//img:before {
//}


//=================================================================================================
// Link styling
//================================================================================================= 
a {
    color: inherit;
    text-decoration: none;
    transition: color $duration $timing;

    &:hover,
    &:active,
    &:focus {
        color: $color-link--hover;
    }
}

.wysiwyg {
    a {
        color: $color-link;
    }
}


//=================================================================================================
// Highlight
//================================================================================================= 
mark {
    background: $color-highlight;
    padding: 0 0.2rem;
}


//=================================================================================================
// Blockquote
//================================================================================================= 
blockquote {
    @include font-size(24);
    padding: 0 1.5rem;
    border-left: $borders--blockquote;
    margin: 0;

    cite {
        display: block;
        font-size: $font-size--text;
        text-align: right;
        margin-top: 0;
    }
}


//=================================================================================================
// Code block styling
//=================================================================================================
pre {
    font-family: $code-family;
    font-size: $code-size;
    color: $code-color;
    background: $code-bg;
    tab-size: 2;
    padding: 1rem;
    border: 0;
    border-radius: $border-radius;
    margin: 0;

    code {
        font-family: $code-family;
        line-height: 1.2;
    }
}


//=================================================================================================
// Keyboard input
//================================================================================================= 
kbd {
    display: inline-block;
    line-height: 1.4;
    font-family: "Helvetica", Arial, sans-serif;
    font-size: $font-size--text-xsmall;
    color: #333;
    text-shadow: 0 1px 0 #fff;
    background-color: #f7f7f7;
    padding: .1em .6em;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    margin: 0 .1em;
}


//=================================================================================================
// Inline code styling
//================================================================================================= 
:not(pre)>code {
    font-family: $code-family;
    font-size: $code-size;
    color: $code-color;
    background: $code-bg;
    padding: 0 0.2rem;
    border: $borders;
    border-radius: $border-radius;
}


//=================================================================================================
// Line break
//================================================================================================= 
hr {
    height: 0;
    border: 0;
    border-top: $borders;
}


//=================================================================================================
// Definition list
//================================================================================================= 
dt {
    font-weight: $font-weight-semibold;
}

dd {
    margin-bottom: .5rem;
}


//=================================================================================================
// Image/video wrapper
//================================================================================================= 
img {
    max-width: 100%;
    height: auto;
}

.image-wrapper {
    min-height: 100%;
    position: relative;
    @include flex($justifyContent: center, $alignItems: center);
    background-color: $color-light;
    overflow: hidden; 
}

.image-wrapper {
    video {
        margin-bottom: 10px;
    }
}


//=================================================================================================
// Gutter - Placement 
//================================================================================================= 
.no-gutter {
    padding-right: 0;
    padding-left: 0;
}

.content-right {
    text-align: right;
}

.container-center {
    @include flex($justifyContent: center, $alignItems: center);
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    body {
        &:not(.home) {
            .nav-main .submenu {
                top: 0;
                border-top: none;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    h1 {
        @include font-size(38);
    }

    h2 {
        @include font-size(28);
    }

    h3 {
        @include font-size(24);
    }

    h4 {
        @include font-size(19);
    }

}