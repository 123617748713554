.footer {
    background-color: $color-dark;
    color: $color-white;
    padding: 95px 0 40px;

    &__content {
        display: flex;
    }

    &__infos {
        display: flex;
    }

    &__coordonnees {
        width: 220px;
        margin-right: 80px;
    }

    &__horaires {
        width: 220px;
        margin-right: 100px;
    }

    &__title {
        font-size: toRem(20);
        font-weight: 700;
        line-height: toRem(25);
        letter-spacing: 0.4px;
    }

    &__text {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(22);
        margin-top: 10px;
    }

    &__button {
        @extend %homeButton;
        border-color: $color-white!important;
        color: $color-white;
        width: 220px;
        margin-top: 30px!important;

        &:before {
            background-color: $color-white;
        }

        span {
            background-color: $color-white;
            
            svg {
                fill: $color-dark;
            }
        }

        &--phone p {
            padding: 0 15px;
        }

        &--contact p {
            padding: 0 18px 0 19px;
        }
    }

    &__plan {
        display: flex;
        align-items: center;
        margin-top: -64px;
        height: 334px;

        div {
            @include flex($alignItems: center);
            @include size(450px, 110px);
            gap: 23px;
            background-color: $color-second;
            color: $color-dark;
            font-weight: 700;
            font-size: toRem(18);
            line-height: toRem(24);
            text-transform: uppercase;
            padding: 0 28px;
            margin-top: 15px;
            position: relative;
            transition: all $duration ease-in-out;

            &:before, &:after {
                content: "";
                z-index: 0;
                transition: all $duration ease-in-out;
            }
    
            &:before {
                @include position(absolute, $top: -10px, $left: -10px);
                @include size(100%);
                border: 2px solid $color-white;
            }
    
            &:after {
                @include position(absolute, $top: -10px, $left: 92px);
                @include size(50px, 28px);
                background-color: $color-white;
                clip-path: polygon(0 0, 50% 100%, 100% 0);
                opacity: 1;
            }

            > svg {
                margin-top: -8px;
            }
        }

        > svg {
            margin-left: -200px;
            position: relative;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            div {
                background-color: rgba($color-second, 0.8);
                &:before {
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    &__menu { 
        margin-top: -20px;
        position: relative;
        z-index: 1;
        width: 900px;

        ul {
            @include flex($wrap: wrap, $alignItems: center);
            width: 100%;
            gap: 45px;
        }

        li {
            a {
                font-weight: 400;
                font-size: toRem(14);
                line-height: toRem(20);

                &:hover, &:focus {
                    color: $color-third;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        &__coordonnees {
            margin-right: 40px;
        }

        &__horaires {
            margin-right: 50px;
        }

        &__plan {
            flex-direction: row-reverse;

            div {
                width: 258px;
                justify-content: flex-end;
                padding: 0 20px;
                gap: 10px;

                > svg {
                    @include size(46px, 39px);
                    margin-top: -5px;
                }

                &:before {
                    left: 10px;
                }

                &:after {
                    left: 102px;
                }
            }

            > svg {
                @include size(227px, 255px);
                margin: -30px -75px 0 0;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        padding: 80px 0 75px;

        &__content {
            justify-content: space-between;
        }

        &__infos {
            flex-direction: column;
            gap: 30px;
        }

        &__plan {
            flex-direction: column-reverse;
            align-items: flex-end;
            height: unset;
            margin: 0 10px 0 0;

            div {
                justify-content: center;
                gap: 28px;

                &:before {
                    left: -10px;
                    width: calc(100% + 20px);
                }

                &:after {
                    left: 173px;
                }
            }

            > svg {
                @include size(251px, 282px);
                margin: 0 0 -64px 0;
                transform: translateX(-80px);
            }

            &:hover, &:focus {
                div {
                    &:before {
                        left: -10px;
                        width: calc(100% + 20px);
                        top: -10px;
                    }
                }
            }
        }

        &__menu {
            margin-top: 50px;
            width: 100%;

            ul {
                gap: 20px 45px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__content {
            flex-direction: column;
            gap: 40px;
        }

        &__infos {
            flex-direction: unset;
            flex-wrap: wrap;
            gap: 40px;
        }

        &__coordonnees, &__horaires {
            margin: 0;
        }

        &__plan {
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;
            margin: 0;

            div {
                margin-top: 5px;
            }

            > svg {
                transform: unset;
                margin: 0 -40px 0 0;
            }
        }

        &__menu {
            margin-top: 45px;

            ul {
                gap: 15px 45px;
            }
        }
    }
}

@media screen and (max-width: 545px) {
    .footer__plan {
        flex-direction: column-reverse;
        align-items: flex-start;

        div {
            margin: -10px 0 0 10px;
        }

        > svg {
            margin: 0;
        }
    }
}