.nav-main {
    z-index: 10;

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
        height: 100%;
    }

    // Main menu
    .menu {
        @include flex($wrap: wrap);
        gap: 50px;

        &__item {
            @include flex($alignItems: center);
            gap: 5px;

            > svg {
                @include size(20px);
                fill: $color-white;
                margin-top: 5px;
                transition: transform $duration ease-in-out;
            }

            &.menu-item-has-children.js-open-submenu {
                svg {
                    transform: rotate(180deg);
                }

                .submenu {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                }

                .menu__link {
                    &::before {
                        opacity: 1;
                    }

                    &:after {
                        width: 100%;
                    }
                }

                &:last-child {
                    .menu__link {
                        &:before {
                            background-color: $color-third;
                        }
    
                        &:after {
                            background-color: $color-third;
                        }
                    }
                }
            }

            &:hover, &:focus {
                .menu__link {
                    &:after {
                        width: 100%;
                    }
                }

                &:last-child {
                    &:before {
                        background-color: $color-third;
                    }

                    .menu__link {
                        &:after {
                            background-color: $color-third;
                        }
                    }
                }
            }
    
            &:last-child {
                &:before {
                    @include position(absolute, $top: -15px, $left: calc(50% + 263px));
                    @include size(70px, 40px);
                    content: "";
                    background-color: $color-main;
                    clip-path: polygon(0 0, 50% 100%, 100% 0);
                    z-index: 0;
                    opacity: 1;
                    transition: all $duration ease-in-out;
                }

               .menu__link {
                    &:after {
                        @include position(absolute, $left: 0, $bottom: -7px);
                        @include size(193px, 2px);
                        content: "";
                        background-color: $color-main;
                        z-index: 0;
                        transition: all $duration ease-in-out;
                    }
                }
            }
        }   
        
        &__link {
            position: relative;
            background-color: $color-bg--transparent;
            color: $color-white;
            transition: {
                property: background-color, color;
                duration: $duration;
                timing-function: $timing;
            }
            font-size: toRem(20); 
            font-weight: 700;
            line-height: toRem(25);
            letter-spacing: 0.4px;

            &:before {
                @include position(absolute, $bottom: -45px, $left: 50%);
                @include size(20px, 10px);
                transform: translateX(-50%);
                content: "";
                background-color: $color-third;
                clip-path: polygon(0 100%, 100% 100%, 50% 0);
                z-index: 0;
                opacity: 0;
                transition: all duration ease-in-out;
            }
            
            &:after {
                @include position(absolute, $left: 0, $bottom: -7px);
                @include size(0, 2px);
                content: "";
                background-color: $color-third;
                z-index: 0;
                transition: width $duration ease-in-out;
            }
        }
    }

    // Sub menu
    .submenu {
        @include flex($justifyContent: center);
        @include position(absolute, $top: 130px, $right:0);
        z-index: 1;
        width: 100%;
        padding: 30px 15px 45px;
        background-color: rgba($color-dark, 0.98);
        box-shadow: $shadow;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: {
            property: visibility, opacity, pointer-events;
            duration: $duration;
            timing-function: $timing;
        }

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
            border-bottom: 1px solid rgba($color-white, 0.5);
            position: relative;

            &::before {
                content: "";
                display: inherit;
                position: absolute;
                bottom: -2px;
                width: 0;
                height: 2px;
                background-color: $color-third;
                transition: width $duration $timing;
            }

            &:hover, &:focus {
                &::before {
                    width: 100%;
                }

                .submenu__link {
                    padding: 15px 30px 15px 15px;
                    color: $color-third;
                    
                    &::after {
                        color: $color-third;
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 15px 30px 15px 0;
            color: $color-white;
            font-weight: 700;
            font-size: toRem(16);
            line-height: toRem(22);
            transition: all $duration ease-in-out;

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: $color-white;
                transition: color $duration $timing;
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
        
        &__close {
            @include absolute($top: 13px);
            @extend %SpaceFull-to-Container--right;
            margin: 0;
            padding: 0;
            background-color: $color-bg--transparent;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            display: none;

            &__icon {
                display: block;
                @include size(26px);
                margin-right: 7px;
                background-color: $color-main;
                border: $btn-border-width $btn-border-style $btn-border-color;
                border-radius: $border-radius--round;
                fill: $color-white;
            }

            &__mobile {
                display: none;
            }

            svg {
                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                .submenu__close__icon {
                    background-color: $color-bg--transparent;
                    fill: $color-main;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close {
        display: none;
    }

}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {
    .nav-main {
        .menu {
            gap: 30px;

            &__item:last-child {
                &:before {
                    left: calc(50% + 199px);
                    top: -13px;
                }
                .menu__link {
                    &:after {
                        width: 173px;
                    }
                }
            }

            &__link {
                font-size: toRem(18);
                line-height: toRem(25);
                letter-spacing: 0.36px;

                &:before {
                    bottom: -35px;
                }
            }
        }

        .submenu {
            top: 110px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        // If menu open
        &.js-open-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-dark;
            padding: 40px 0;
            transition: left $duration $timing;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 7px;
            min-height: 69px;
            margin: 0;
            padding: 0;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            font-size: toRem(18);
            font-weight: 700;
            line-height: toRem(25);
            letter-spacing: 0.36px;
            color: $color-white;
            text-transform: capitalize;
            cursor: pointer;

            &:hover, &:focus {
                color: $color-white;
                background-color: transparent;
            }
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: -1px;
            display: block;
            width: 18px;
            height: 2px;
            background-color: $color-white;

            &:before, 
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background-color: $color-white;
            }

            &:before {
                top: 6px;
            }

            &:after {
                top: -6px;
            } 
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: transparent;
            border: $btn-border-width $btn-border-style $color-main;
            border-radius: $border-radius--round;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-white;
            }

            &:hover,
            &:focus {
                background-color: $color-main;
                border-color: $color-main;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            gap: 50px;
            padding: 35px 40px 50px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: fit-content!important;

                > svg {
                    transform: rotate(-90deg);
                }
    
                &.menu-item-has-children {

                    // If sub menu open
                    &.js-open-submenu {   
                        .submenu {
                            right: 0;
                        }
    
                        .menu__link {
                            background-color: $color-bg--transparent;
                            color: $color-white;
                        }
                    }
                }

                &:last-child {
                    &:before {
                        display: none;
                    }
                    
                    .menu__link {
                        &:after {
                            width: 100%;
                            bottom: -10px;
                        }
                    }
                }
    
                &:hover, &:focus {   
                    .menu__link {
                        background-color: $color-bg--transparent;
                        color: $color-white;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                font-size: toRem(22);
                color: $color-white;
                transition: none;

                &:before {
                    display: none;
                }

                &::after {
                    width: 100%;
                    bottom: -10px;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 40px 0 60px 0;
            background-color: $color-dark;
            transition: {
                property: visibility, pointer-events, right;
                duration: $duration;
                timing-function: $timing;
            }
            opacity: 1;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: transparent;
                border: $btn-border-width $btn-border-style $color-main;
                border-radius: $border-radius--round;
                order: 1;

                &:hover,
                &:focus {
                    background-color: $color-main;
                    border-color: $color-main;

                    svg {
                        fill: $color-dark;
                    }
                }

                &__mobile {
                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: $color-white;
                }

                &__icon, span {
                    display: none;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0 40px;
                overflow-y: auto;
                order: 2;
            }

            &__item {
                margin: auto; 
            }

            &__link {
                color: $color-white;

                &::after {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }


}
