//======================================================================================================
// Réseaux sociaux
//======================================================================================================
.sticky {
    position: fixed;
    z-index: 10;
    right: 0;
    top: 325px;
    
    &__container {
        @include flex($direction: column);
        gap: 5px;
    }

    &__link {
        @include size(50px);
        background-color: $color-dark;
        @include flex($alignItems: center, $justifyContent: center);
        transition: all $duration ease-in-out;

        svg {
            @include size(100%);
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-main;

            svg {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    &:before {
        @include position(absolute, $top: 0, $left: 0);
        content: "";
        @include size(100%);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 52.08%, rgba(0, 0, 0, 0.05) 100%);
        z-index: 2;
        transition: all duration ease-in-out;
    }

    > .container {
        position: relative;
    }

    .container--pagination {
        @include absolute(0, 0, 0, 0);
        height: 100%;
    }

    .slideshow {
        @include size(100%, 800px);

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            @include size(100%);
        }

        &__container {
            display: inherit;
            position: relative;
            @include size(100%);
        }

        &--video {
            img {
                display: none;
            }
        }

        &__pagination {
            @include absolute($bottom: 30px, $left: 50%);
            transform: translateX(-50%);
            @include flex($alignItems: center, $justifyContent: center);
            box-shadow: 0px 0px 20px 0px rgba(34, 41, 43, 0.60);
            z-index: 2;
            width: auto;
            gap: 14px;
    
            .swiper-pagination-bullet {
                @include size(14px);
                background-color: $color-white;
                border-radius: 50%;
                opacity: 1;
                border: 3px solid $color-white;
                transition: {
                    property: width, background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
    
                &:hover, &:focus {
                    background-color: transparent;
                }
    
                &-active {
                    background-color: transparent;
                }
            }
        }
    }

    .search-tools {
        @include position(absolute, $top: 480px, $left: 50%);
        transform: translateX(-50%);
        z-index: 2;

        .search-container {
            width: 570px;
            z-index: 3;
    
            input {
                padding: 0;
                height: 50px;
                border: none;
                padding: 0 0 0 20px;
                background: $color-white;
                color: $color-dark;
                opacity: 1;
                box-shadow: 0;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                transition: all $duration ease-in-out;
    
                &::placeholder {
                    font-size: toRem(16);
                    font-weight: 400;
                    color: $color-gray;
                    opacity: 1;
                }

                &::-webkit-search-cancel-button {
                    display: none;
                }
            }

            input.input-saisie::-webkit-search-cancel-button {
                display: none;
            }

            span {
                @include size(50px);
                background-color: $color-main;

                &:before {
                    display: none;
                }

                svg {
                    @include size(40px);
                    display: flex;
                    fill: $color-dark;
                    transition: all $duration ease-in-out;
                }

                &:hover, &:focus {
                    background-color: $color-third;
                }
            }

            &-open {
                .searchInput {
                    background: rgba($color-white, 1);
                    box-shadow: $shadow;
                }
            }
        }

        .searchResult {
            width: 570px;
        }
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.fast-access {
    position: relative;
    background-color: $color-second;
    height: 400px;
}

.access {
    @include position(absolute, $top: 0, $left:  calc(50% - 570px));
    @include size(865px, 100%);
    

    &__blocs {
        @include flex($wrap: wrap);
        gap: 64px 60px;
        width: 600px;
        margin-top: 55px;
    }

    &__bloc {
        position: relative;
        width: 160px;
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        gap: 10px;
        font-weight: 400;
        font-size: toRem(16);
        line-height: toRem(22);
        text-align: center;

        &:before {
            @include position(absolute, $bottom: -15px, $left: 50%);
            @include size(12px, 8px);
            transform: translateX(-50%);
            content: "";
            background-color: $color-white;
            clip-path: polygon(0 100%, 100% 100%, 50% 0);
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        svg {
            @include size(80px, 50px);
            transition: all $duration ease-in-out;
            
            path {
                transition: all $duration ease-in-out;
            }   
        }

        &:hover, &:focus {
            &:before {
                @include size(16px, 11px);
            }

            svg {
                transform: scale(1.08);

                path {
                    &[fill="white"] {
                        fill: $color-fourth;
                    }
                }
            }
        }
    }
}

.quick-links {
    @include position(absolute, $top: 0, $left: calc(50% + 115px));
    @include size(1500px, 100%);
    background-color: $color-light;

    &__blocs {
        @include flex($direction: column);
        margin: 50px 0 0 70px; 
    }

    &__bloc {
        position: relative;
        @include flex($alignItems: center, $justifyContent: space-between);
        @include size(400px, 50px);
        border-bottom: 2px solid $color-second;
        transition: all $duration ease-in-out;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(0, 100%);
            content: "";
            background-color: $color-second;
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        p {
            font-weight: 400;
            font-size: toRem(16);
            line-height: toRem(22);
            z-index: 1;
        }
        
        svg {
            @include size(26px);
            fill: $color-second;
            z-index: 1;
            transition: all $duration ease-in-out;
        }

        &:first-child {
            border-top: 2px solid $color-second;
        }

        &:hover, &:focus {
            padding-left: 15px;

            &:before {
                width: 100%;
            }

            svg {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    margin: 120px 0;

    &__container {
        position: relative;

        &:before {
            @include position(absolute, $top: 385px, $left: -450px);
            @include size(1000px, 345px);
            content: "";
            background-color: rgba($color-main, 0.2);
            z-index: 0;
            transition: all $duration ease-in-out;
        }
    }

    &__header {
        @include flex($direction: column);
        gap: 10px;
    }

    &__title {
        @extend %homeTitle;
    }

    &__subtitle {
        @extend %homeSubTitle;
        color: $color-main;

        svg {
            fill: $color-main;
        }
    }

    &__content {
        @include flex($direction: column);
        gap: 50px;
        margin-top: 50px;
        position: relative;

        &:before, &:after {
            content: "";
            z-index: 2;
            transition: all $duration ease-in-out;
        }
        
        &:before {
            @include position(absolute, $left: -700px, $bottom: -30px);
            @include size(700px, 300px);
            background-color: $color-white;
        }

        &:after {
            @include position(absolute, $left: -700px, $top: 455px);
            @include size(700px, 115px);
            background-color: rgba($color-main, 0.2);
        }
    }

    &__swiper {
        overflow: visible!important;
        max-width: 1170px;
        margin: 0!important;
    }

    &__item {
        &--big {
            @include flex($alignItems: center);
            gap: 50px;
        }

        &--small {
            @include flex($direction: column);
            gap: 15px;
            transition: all $duration ease-in-out!important;

            &:hover, &:focus {
                .news__titlep--small::before {
                    width: 60px;
                }
            }
        }

        &:hover, &:focus {
            .news__image img {
                transform: scale(1.075);
            }

            .news__info::after {
                left: -45px;
            }
        }
    }

    &__info {
        max-width: 290px;
        position: relative;

        &:before, &:after {
            content: "";
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        &:before {
            @include position(absolute, $top: -65px, $left: -90px);
            @include size(440px, calc(100% + 120px));
            border: 2px solid $color-dark;
        }

        &:after {
            @include position(absolute, $top: -75px, $left: -30px);
            @include size(70px, 40px);
            background-color: $color-main;
            clip-path: polygon(0 0, 50% 100%, 100% 0);
            opacity: 1;
        }
    }

    &__titlep {
        @extend %removeMargin;
        font-weight: 700;

        &--big {
            font-size: toRem(34);
            line-height: toRem(40);
            letter-spacing: 0.68px;
        }

        &--small {
            font-size: toRem(20);
            line-height: toRem(25);
            letter-spacing: 0.4px;
            position: relative;

            &:before {
                @include position(absolute, $left: 0, $bottom: -15px);
                @include size(50px, 2px);
                content: "";
                background-color: $color-dark;
                z-index: 0;
                transition: width $duration ease-in-out;
            }
        }
    }
    
    &__introduction {
        margin-top: 12px;
    }

    &__image {
        overflow: hidden;
        margin: 0;

        &--big {
           @include size(770px, 470px);
        }

        &--small {
            @include size(270px, 165px);
        }

        img {
            object-fit: cover;
            @include size(100%);
            transform: scale(1);
            transition: all $duration ease-in-out;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-light;
        background-position: center;

        &--big {
            @include size(770px, 470px);
        }

        &--small {
            @include size(270px, 165px);
        }
    }

    &__bottom {
        margin-top: 60px;
        @include flex($alignItems: center, $justifyContent: space-between);
    }

    &__button {
        @extend %homeButton;
    }

    &__navigation {
        display: flex;
        gap: 15px;

        &--prev, &--next {
            @extend %navigation;
        }

        &.disable {
            display: none;
        }
    }
}

//======================================================================================================
// Agenda
//======================================================================================================

.home-events {
    color: $color-white;
    background-color: $color-dark;
    padding: 120px 0 170px;

    &__header {
        @include flex($direction: column);
        gap: 10px;
    }

    &__title {
        @extend %homeTitle;
    }

    &__subtitle {
        @extend %homeSubTitle;
        color: $color-third;

        svg {
            fill: $color-third;
        }
    }

    &__content {
        margin-top: 50px;
        position: relative;
        
        &:before {
            @include position(absolute, $left: -705px, $top: 0);
            @include size(700px, 450px);
            content: "";
            background-color: $color-dark;
            z-index: 2;
            transition: all $duration ease-in-out;
        }
    }

    &__swiper {
        // overflow: visible!important;
        overflow: visible!important;
        max-width: 1170px;
        margin: 0!important;
    }

    &__link {
        // width: 370px!important;
        transition: all $duration ease-in-out!important;
        position: relative;

        &:hover, &:focus {
            .home-events__date {
                top: 290px;
            }

            .home-events__image img {
                transform: scale(1.075);
            }
        }
    }

    &__image {
        overflow: hidden;
        margin: 0 0 15px 0;
        @include size(100%, 340px);

        img {
            object-fit: cover;
            @include size(100%);
            transform: scale(1);
            transition: all $duration ease-in-out;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-light;
            background-position: center;
            @include size(100%, 340px);
        }
    }

    &__date {
        @include position(absolute, $left: -5px, $top: 270px);
        @include flex($alignItems: center);
        background-color: $color-third;
        font-weight: 700;
        font-size: toRem(20);
        line-height: toRem(25);
        letter-spacing: 0.4px;
        height: 50px;
        padding: 0 15px;
        color: $color-dark;
        text-transform: lowercase;
        transition: top $duration ease-in-out;

        span {
            @include size(8px, 12px);
            background-color: $color-white;
            clip-path: polygon(100% 50%, 0 0, 0 100%);
            margin: 0 10px;
        }
    }

    &__title--small {
        @extend %removeMargin;
        font-weight: 700;
        font-size: toRem(28);
        line-height: toRem(35);
        letter-spacing: 0.56px;
        color: $color-white;
        max-width: 320px;
    }

    &__bottom {
        @include flex($alignItems: center, $justifyContent: space-between);
        margin-top: 60px;
    }

    &__button {
        @extend %homeButton;
        color: $color-white;
        border-color: $color-third!important;

        &:before {
            background-color: $color-third;
        }

        span {
            background-color: $color-third;
            
            svg {
                fill: $color-dark;
            }
        }
    }

    &__navigation {
        display: flex;
        gap: 15px;

        &--prev, &--next {
            @extend %navigation;
            border-color: $color-third;

            svg {
                fill: $color-white;
            }

            &:hover, &:focus {
                background-color: $color-third;
            }
        }

        &.disable {
            display: none;
        }
    }
}

//======================================================================================================
// Newsletter
//======================================================================================================

.home-newsletter {
    &__link {
        @include flex($alignItems: center, $justifyContent: space-between);
        height: 100px;
        overflow: hidden; 
        background-color: $color-light;
        margin-top: -50px;
        padding: 0 30px;
        position: relative;

        &:before, &:after {
            content: "";
            z-index: 0;
            background-color: $color-second;
            transition: all 0.5s ease-in-out;
        }

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(300px, 100%);
        }

        &:after {
            @include position(absolute, $top: 0, $left: 300px);
            @include size(45px, 100%);
            clip-path: polygon(0 0, 0% 100%, 100% 50%);
        }

        p {
            font-weight: 400;
            font-size: toRem(18);
            line-height: toRem(24);
            letter-spacing: 0.36px;
            position: relative;
            z-index: 1;
            margin-left: -115px;
        }
    
        > svg {
            @include size(26px);
            fill: $color-second;
            position: relative;
            z-index: 1;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            &:before {
                width: 100%;
            }

            &:after {
                left: 1170px;
            }

            > svg {
                fill: $color-dark;
            }
        }
    }

    &__title {
        @include flex($alignItems: center);
        gap: 25px;
        position: relative;
        z-index: 1;
        
        h2 {
            @extend %removeMargin;
            font-weight: 300;
            font-size: toRem(40);
            line-height: toRem(50);
            letter-spacing: 0.8px;
        }
    }
}

//======================================================================================================
// Kiosque
//======================================================================================================

.kiosque {
    margin: 120px 0 200px;

    &__header {
        @include flex($direction: column);
        gap: 10px;
    }

    &__title {
        @extend %homeTitle;
    }

    &__subtitle {
        @extend %homeSubTitle;
        color: $color-main;

        svg {
            fill: $color-main;
        }
    }

    &__container {
        display: flex;
    }

    &__left {
        width: 455px;
    }

    &__info {
        @include flex($direction: column, $justifyContent: space-between);
        max-width: 315px;
        height: 235px;
        margin-top: 75px;
    }

    &__size {
        @include flex($direction: column);
        font-weight: 400;
        font-size: toRem(16);
        line-height: toRem(22);
        color: $color-gray;
        margin-top: -50px;

        span {
            text-transform: uppercase;
        }
    }

    &__title--small {
        @extend %removeMargin;
        font-size: toRem(28);
        font-weight: 700;
        line-height: toRem(35);
    }

    &__see-more {
        @extend %homeButton;
        max-width: 250px;
        justify-content: space-between;

        p {
            padding: 0 17px;
        }
    }

    &__buttons {
        @include flex($direction: column);
        gap: 10px;
        margin-top: 47px;
    }

    &__button {
        @include size(65px, 50px);
        @include flex($alignItems: center, $justifyContent: center);
        background-color: rgba($color-main, 0.2);
        transition: all $duration ease-in-out;

        svg {
            fill: $color-dark;
            @include size(30px);
            transition: all $duration ease-in-out; 
        }

        &:hover, &:focus {
            background-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }

    &__right {
        width: 630px;
        overflow: hidden;
        margin-top: 30px;
    }

    .swiper-container {
        &__content {
            .swiper-slide {
                @include flex($justifyContent: space-between);
                background-color: $color-white;
                opacity: 0!important;
                @include size(455px, 310px);
                box-sizing: border-box;

                &-active {
                    opacity: 1!important;
                }
            }
        }

        &__images {
            @include size(245px, 345px);
            overflow: visible;
            margin: 20px 0;

            .swiper-wrapper {
                display: flex;
                align-items: center;

                .swiper-slide {
                    position: relative;
                    z-index: 0;
                    height: 345px!important;
                    box-shadow: $shadow;
                    transition: all $duration ease-in-out;

                    & img {
                        @include size(100%);
                        object-fit: cover;
                        transition: all $duration ease-in-out;
                    }
                }
            }
        }
    }

    &__navigation {
        @include flex($direction: column);
        gap: 15px;
        margin: 165px 0 0 35px;

        &--prev, &--next {
            @extend %navigation;
        }

        &.disable {
            display: none;
        }
    }
}

//======================================================================================================
// Projets
//======================================================================================================

.projets {
    margin-bottom: 120px;

    &__container {
        position: relative;

        &:before {
            @include position(absolute, $top: -80px, $left: -450px);
            @include size(1000px, 390px);
            content: "";
            background-color: rgba($color-third, 0.2);
            z-index: 0;
            transition: all $duration ease-in-out;
        }
    }

    &__header {
        @include flex($direction: column);
        gap: 10px;
        @include position(absolute, $top: 0, $right:75px);
        z-index: 2;
    }

    &__title {
        @extend %homeTitle;
        line-height: toRem(65);
    }

    &__subtitle {
        @extend %homeSubTitle;
        color: $color-third;

        svg {
            fill: $color-third;
        }
    }

    &__blocs {
        overflow: visible!important;
    }

    .swiper-slide {
        opacity: 0!important;

        &-active {
            opacity: 1!important;
        }
    }

    &__link {
        display: flex;
        gap: 50px;
        cursor: pointer;

        &:hover, &:focus {
            .projets__image img {
                transform: scale(1.075);
            }

            .projets__content::after {
                left: -35px;
            }
        }
    }

    &__image {
        overflow: hidden;
        @include size(700px, 515px);

        img {
            object-fit: cover;
            @include size(100%);
            transform: scale(1);
            transition: all $duration ease-in-out;
        }
    }

    &__content {
        @include size(370px, fit-content);
        margin-top: 245px;
        position: relative;

        &:before {
            @include position(absolute, $top: -40px, $left: -80px);
            @include size(496px, calc(100% + 75px));
            content: "";
            border: 2px solid $color-dark;
            z-index: 0;
        }

        &:after {
            @include position(absolute, $top: -50px, $left: -10px);
            @include size(70px, 40px);
            content: "";
            background-color: $color-third;
            clip-path: polygon(0 0, 50% 100%, 100% 0);
            z-index: 0;
            opacity: 1;
            transition: all $duration ease-in-out;
        }
    }

    &__title--small {
        font-size: toRem(28);
        font-weight: 700;
        line-height: toRem(35);
        letter-spacing: 0.56px;
    }

    &__description {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(22);
        margin-top: 10px;
    }

    &__bottom {
        @include flex($alignItems: center, $justifyContent: flex-end);
        gap: 85px;
        margin-top: -50px;
        position: relative;
        z-index: 1;
    }

    &__button {
        @extend %homeButton;
        border-color: $color-third!important;
        width: 220px;

        &:before {
            background-color: $color-third;
        }

        p {
            padding: 0 18px 0 17px !important;
        }

        span {
            background-color: $color-third;
        }
    }

    &__navigation {
        display: flex;
        gap: 15px;

        &--prev, &--next {
            @extend %navigation;
            border-color: $color-third;

            &:hover, &:focus {
                background-color: $color-third;
            }
        }

        &.disable {
            display: none;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .sticky {
        top: 240px;
    }

    .home-slideshow {
        .slideshow {
            height: 640px
        }

        .search-tools {
            top: 350px;
        }
    }
    
    .access {
        left: calc(50% - 470px);
        width: 660px;
    
        &__blocs {
            gap: 60px 15px;
        }
    }
    
    .quick-links {
        left: calc(50% + 90px);

        &__blocs {
            margin: 50px 0 0 50px;
        }

        &__bloc {
            width: 330px;
        }
    }

    .news {
        &__container {
            &:before {
                @include size(570px, 240px);
                left: -120px;
            }
        }

        &__content {
            &:after {
                width: 130px;
                left: -130px;
                top: 370px;
            }
        }

        &__swiper {
            max-width: 940px;
        }

        &__item--big {
            gap: 35px;

            &:hover, &:focus {
                .news__info::after {
                    left: -5px;
                }
            }
        }
    
        &__image {
            &--big {
               @include size(585px, 380px);
            }
    
            &--small {
                @include size(220px, 145px);
            }
        }
    
        .notimg {
            &--big {
                @include size(585px, 380px);
            }
    
            &--small {
                @include size(220px, 145px);
            }
        }

        &__info {
            margin-top: 20px;

            &:before {
                left: -60px;
                top: -50px;
                @include size(380px, calc(100% + 90px));
            }

            &:after {
                top: -55px;
                left: -25px;
                @include size(50px, 30px);
            }
        }

        &__titlep--small {
            font-size: toRem(19);
        }

        &__introduction {
            line-height: toRem(22);
        }
    }

    .home-events {
        &__swiper {
            max-width: 940px;
        }
    
        &__link {
            // width: 300px!important;
    
            &:hover, &:focus {
                .home-events__date {
                    top: 225px;
                }
            }
        }
    
        &__image {
            height: 275px;
    
            &--notimg {
                height: 275px;
            }
        }
    
        &__date {
            top: 205px
        }
    
        &__title--small {
            max-width: 100%;
        }
    }

    .home-newsletter {
        &__link {
            &:after {
                width: 25px;
            }
    
            p {
                margin-left: 35px;
            }
    
            &:hover, &:focus {
                &:after {
                    left: 940px;
                }
            }
        }
    }

    .kiosque {
        &__left {
            width: 370px;
        }
    
        &__info {
            max-width: 250px;
            height: 255px;
            margin-top: 55px;
        }
    
        &__size {
            margin-top: -35px;
        }
    
        &__buttons {
            @include flex($direction: column);
            gap: 10px;
            margin-top: 47px;
        }
    
        &__right {
            width: 490px;
        }
    
        .swiper-container {
            &__content {
                .swiper-slide {
                    width: 370px;
                }
            }
        }

        &__navigation {
            margin: 165px 0 0 30px;
        }
    }

    .projets {
        margin-bottom: 120px;
    
        &__container {
            position: relative;
    
            &:before {
                left: -120px;
                top: -80px;
                @include size(590px, 290px);
            }
        }
    
        &__header {
            top: -80px;
            right: 20px;
        }
    
        &__link {
            gap: 30px;
    
            &:hover, &:focus {
                .projets__content::after {
                    left: -20px;
                }
            }
        }
    
        &__image {
            overflow: hidden;
            @include size(540px, 420px);
        }
    
        &__content {
            width: 340px;
            margin-top: 140px;
    
            &:before {
                @include size(420px, calc(100% + 65px));
                left: -53px;
                top: -35px;
            }
    
            &:after {
                @include size(50px, 30px);
                left: 0;
                top: -45px;
            }
        }

        &__bottom {
            gap: 35px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .sticky {
        right: unset;
        top: unset;
        bottom: 0;
        width: 100vw;

        &__container {
            flex-direction: row;
            margin: 0 auto;
            max-width: 90%;
            padding: 0 10px;
        }
    
        &__link {
            @include size(40px);
            border: 1px solid $color-white;
            border-bottom: none;
        }
    }

    .home-slideshow {
        position: relative;

        .slideshow {
            height: 450px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__pagination {
                bottom: 20px;
            }
        }

        .search-tools {
            display: none;
        }
    }

    .fast-access {
        @include flex($direction: column);
        height: fit-content;
    }
    
    .access {
        @include position(relative, $top: unset, $left: unset);
        width: 100%;
    
        &__blocs {
            gap: 50px 40px;
            width: 100%;
            margin: 30px auto 60px;
            max-width: 90%;
            padding: 0 10px;
            justify-content: center;
        }
    
        &__bloc {
            width: 120px;
            font-size: toRem(14);
            line-height: toRem(20);
            letter-spacing: 0.28px;

            &:before {
                bottom: -18px;
            }
        }
    }
    
    .quick-links {
        @include position(relative, $top: unset, $left: unset);
        @include size(100%, 100%);
    
        &__blocs {
            @include flex($direction: row, $wrap: wrap, $justifyContent: space-between);
            margin: 30px auto;
            max-width: 90%;
            padding: 0 10px;
        }
    
        &__bloc {
            width: calc(50% - 10px);

            &:nth-child(2) {
                border-top: 2px solid $color-second;
            }
        }
    }

    .news {
        margin: 100px 0;
    
        &__container {
            &:before {
                @include size(250px, 120px);
                top: 178px;
            }
        }
    
        &__content {
            gap: 40px;
    
            &:after {
                display: none;
            }
        }
    
        &__swiper {
            max-width: 100%;
        }
    
        &__item {
            &--big {
                gap: 10px;
            }

            &:hover, &:focus {
                .news__info::after {
                    left: -20px;
                }
            }
        }
    
        &__info {
            max-width: 50%;
            margin: 0;
            padding: 29px;

            &:before {
                top: 0;
                left: 0;
                @include size(100%);
            }
    
            &:after {
                left: -20px;
                top: 35px;
                transform: rotate(-90deg);
            }
        }
    
         
        &__titlep {
            &--big {
                font-size: toRem(28);
                line-height: toRem(35);
                letter-spacing: 0.56px;
            }
        }
        
        &__introduction {
            margin-top: 5px;
        }
    
        &__image {
            &--big {
               @include size(calc(50% - 10px), 225px);
            }
    
            &--small {
                @include size(100%, 145px);
            }
        }
    
        .notimg {
            &--big {
                @include size(calc(50% - 10px), 225px);
            }
    
            &--small {
                @include size(100%, 145px);
            }
        }
    }

    .home-events {
        padding: 100px 0 150px;
    
        &__swiper {
            max-width: 100%;
        }
    
        &__link {
            &:hover, &:focus {
                .home-events__date {
                    top: 260px;
                }
            }
        }
    
        &__image {
            height: 310px;
    
            &--notimg {
                height: 310px;
            }
        }
    
        &__date {
            top: 245px;
        }
    
        &__bottom {
            margin-top: 50px;
        }
    }

    .home-newsletter {
        &__link {
            &:before {
                width: 230px;
            }

            &:after {
                left: 230px;
            }

            p {
                margin: 0 20px 0 70px;
            }

            > svg {
                min-width: 26px;
            }
    
            &:hover, &:focus {
                &:after {
                    left: 100%;
                }
            }
        }

        &__title {
            gap: 15px;

            h2 {
                font-size: toRem(30);
                letter-spacing: 0.6px;
            }

            svg {
                width: 35px;
                height: 40px;
            }
        }
    }

    .kiosque {
        margin: 100px 0;

        &__left {
            width: 60%;
        }

        &__title--small {
            max-width: 90%;
        }
    
        &__info {
            max-width: unset;
        }
    
        &__button {
            width: 55px;
        }
    
        &__right {
            width: 215px;
            min-width: 215px;
        }
    
        .swiper-container {
            &__content {
                .swiper-slide {
                    width: 100%!important;
                }
            }

            &__images {
                width: 215px;
                .swiper-wrapper .swiper-slide {
                    width: 215px!important;
                    height: 300px!important;
                }
            }
        }

        &__navigation {
            margin: 165px 0 0 10px;

            .swiper-button-disabled {
                opacity: 0.35;
            }
        }
    }

    .projets {
        margin-bottom: 100px;

        &__container::before {
            @include size(315px, 130px);
            top: 180px;
        }

        &__header {
            position: relative;
            top: unset;
            right: unset;
        }

        &__title {
            br {
                display: none;
            }
        }

        &__blocs {
            margin-top: 50px;
        }

        &__link {
            gap: 10px;
        }

        &__image {
            @include size(calc(50% - 10px), 205px);
        }

        &__content {
            margin: 0;
            width: 50%;
            padding: 20px 30px;
            box-sizing: border-box;

            &:before {
                @include size(100%);
                left: 0;
                top: 0;
            }

            &:after {
                top: 40px;
                left: -20px;
                bottom: unset;
                transform: rotate(-90deg);
            }
        }

        &__description {
            margin-top: 5px;
        }

        &__bottom {
            justify-content: space-between;
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 785px) {
    .kiosque__left {
        width: 51%;
    }
}


// 640
@media screen and (max-width: $small) {
    .home-slideshow {
        .slideshow {
            height: 420px;
        }
    }

    .quick-links {
        &__blocs {
           flex-direction: column;
        }
    
        &__bloc {
            width: 100%;

            &:nth-child(2) {
                border-top: none;
            }
        }
    }

    .news {
        margin: 80px 0;

        &__container::before {
            top: 148px;
        }
        
        &__header {
            gap: 0;
        }
    
        &__content {
            gap: 20px;
            margin-top: 30px;
        }

        .swiper-slide {
            opacity: 0.4!important;

            &-active {
                opacity: 1!important;
            }
        }
    
        &__item {
            &--big {
                flex-direction: column;
            }

            &:hover, &:focus {
                .news__info::after {
                    left: 20px;
                }
            }
        }
    
        &__info {
            max-width: 100%;
            margin: 0;
            padding: 30px 20px 25px;

            &:before {
                top: 0;
                left: 0;
                @include size(100%);
            }
    
            &:after {
                left: 20px;
                top: -10px;
                transform: rotate(0);
            }
        }
    
        &__image {
            &--big {
               @include size(100%, 200px);
            }
    
            &--small {
                @include size(100%, 190px);
            }
        }
    
        .notimg {
            &--big {
                @include size(100%, 200px);
            }
    
            &--small {
                @include size(100%, 190px);
            }
        }

        &__bottom {
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 30px;
        }
    }

    .home-events {
        padding: 80px 0 130px;

        &__header {
            gap: 0;
        }

        &__content {
            margin-top: 30px;
        }
    
        &__swiper {
            // min-height: fit-content;
        }

        .swiper-slide {
            opacity: 0.4!important;

            &-active {
                opacity: 1!important;
            }
        }
    
        &__link {
            // width: 100%!important;
            
            &:hover, &:focus {
                .home-events__date {
                    top: 220px;
                }
            }
        }
    
        &__image {
            height: 270px;
    
            &--notimg {
                height: 270px;
            }
        }
    
        &__date {
            top: 205px;
        }

        &__bottom {
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 30px;
            margin-top: 45px;
        }
    }

    .home-newsletter {
        &__link {
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            height: 235px;

            &:before {
                @include size(100%, 80px);
            }

            &:after {
                @include size(100%, 25px);
                top: 80px;
                left: 0;
                clip-path: polygon(0 0, 50% 100%, 100% 0);
            }

            p {
                margin: 0 0 40px;
                width: 90%;
            }

            > svg {
                position: absolute;
                right: 25px;
                bottom: 30px;
            }
    
            &:hover, &:focus {
                &:after {
                    left: 0;
                }
            }
        }

        &__title {
            margin: 15px auto;
        }
    }

    .kiosque {
        margin: 80px 0 67px;

        &__container {
            flex-direction: column-reverse;
            position: relative;
            padding-top: 85px;
        }

        &__header {
            gap: 0;
            @include position(absolute, $top: 0);
        }

        &__left {
            width: 100%;
        }

        &__title--small {
            margin-right: 0!important;
        }

        &__size {
            margin: -100px 0 0;
        }
    
        &__info {
            max-width: 100%;
            margin: 0;
            height: 285px;

        }

        &__buttons {
            @include position(absolute, $top: -282px, $left: 245px);
            margin: 0;
        }
    
        &__right {
            width: 245px;
            min-width: 245px;
            margin: 0;
        }
    
        .swiper-container {
            &__content {
                overflow: visible!important;

                .swiper-slide {
                    width: 100%!important;
                }
            }

            &__images {
                width: 245px;
                .swiper-wrapper .swiper-slide {
                    width: 245px!important;
                    height: 345px!important;
                }
            }
        }

        &__navigation {
            @include position(absolute, $bottom: 105px);
            margin: 0;
            flex-direction: row;
        }
    }

    .projets {
        margin-bottom: 80px;

        &__container::before {
            top: 215px;
        }

        &__header {
            gap: 0;
        }

        &__title {
            br {
                display: flex;
            }
        }

        &__blocs {
            margin-top: 30px;
        }

        &__link {
            flex-direction: column;

            &:hover, &:focus {
                .projets__content::after {
                    left: 20px;
                }
            }
        }

        &__image {
            @include size(100%, 180px);
        }

        &__content {
            width: 100%;
            padding: 20px;

            &:after {
                top: -10px;
                left: 20px;
                transform: rotate(0);
            }
        }

        &__bottom {
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 30px;
            margin-top: 30px;
        }
    }
}
