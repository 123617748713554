.nav-main--burger {
    z-index: inherit;
    height: 15px;
    background-color: $color-main;

    // Burger menu Button
    .nav-main-buttons {
        position: relative;

        &__menu {
            @include absolute($bottom: 0, $right: 15px);
            z-index: 11;
            @include flex($justifyContent: flex-start, $alignItems: center);
            column-gap: 15px;
            width: fit-content;
            margin: 0;
            padding: 30px;
            background-color: $color-main;
            border: 0;
            border-radius: 0;
            transition: bottom $duration $timing;
            cursor: pointer;

            &::after {
                content: "Menu";
                font-size: $font-size--5;
                font-weight: $font-weight; 
                color: $color-white;
                text-transform: uppercase;
            }

            // Burger menu Button icon
            &__icon {
                position: relative;
                top: -1px;
                display: block;
                @include size(20px, 2px);
                background-color: $color-white;
                transition: transform $duration $timing;

                &::before, 
                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    @include size(100%);
                    background-color: $color-white;;
                    transition: {
                        property: width, top, left, transform;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }

                &::before {
                    top: 7px;
                }

                &::after {
                    top: -7px;
                } 
            }
        }

        // For responsive
        &__submenu {
            display: none;
        }

    }

    // Popup menu
    &__popup {
        @include fixed($top: -100vh, $left: 0, $right: 0);
        z-index: 10;
        display: block;
        max-width: inherit;
        @include size(100%, 100vh);
        background-color: $color-main;
        padding: 0;
        transition: {
            property: top, left;
            duration: .5s, $duration;
            timing-function: $timing;
        }

        &::before {
            content: '';
            @include absolute($top: 0, $left: 0, $right: 20px);
            z-index: 1;
            height: 170px;
            background-color: $color-main;
        }
    }

    // Section menu (for overflow)
    &__section {
        overflow-y: auto;
        height: 100%;
        padding: 40px 0 20px 0;
    }

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: flex-start);
    }

    // if Burger menu open
    &.js-open-menu {

        // Burger menu Button
        .nav-main-buttons__menu {   
            bottom: -7px;

            &::after {
                content: "Fermer";
            }

            // Burger menu Button icon
            &__icon {
                transform: rotateZ(-45deg);

                &::before, &::after {
                    width: 10px;
                    left: 5px;
                    transform: rotateZ(90deg);
                }
    
                &::before {
                    top: -6px;
                }
    
                &::after {
                    top: 6px;
                }
            }
        }

        // Popup menu
        .nav-main--burger__popup {
            top: 0;
            transition: top .7s cubic-bezier(0.4, 0, 0.2, 1);
        }

        // Main menu
        .menu {
            position: relative;
            display: flex;
        }

    }

    // Main menu
    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px;
        @include size(40%, auto);
        margin-top: 130px;
        margin-bottom: 40px;
        padding: 0 50px 0 0;

        &::after {
            content: '';
            @include absolute($top: 0, $right: 0);
            @include size(1px, 100%);
            background-color: $color-second;
        }

        &__item {
            @include flex($alignItems: center, $justifyContent: space-between);
            width: 100%;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);

            &.menu-item-has-children {
                .menu__link {
                    &::after {
                        content: '\f345';
                        font: normal 1.250rem/1 dashicons;
                        color: rgba(255, 255, 255, 0.3);
                        transition: color $duration $timing;
                    }

                    &:hover, &:focus {
                        &::after {
                            color: $color-white;
                        }
                    }
                }

                &.js-open-submenu {
                    .submenu {
                        display: block;
                    }
                }
            }
        }

        &__link {
            position: relative;
            @include flex($alignItems: center, $justifyContent: space-between);
            max-width: inherit;
            @include size(100%, auto);
            padding: 20px 0;
            color: $color-white;
            text-transform: uppercase;

            &::before {
                content: '';
                @include absolute($left: 0, $bottom: 0);
                @include size(0, 1px);
                background-color: $color-white;
                transition: width $duration $timing;
            }

            &:hover, &:focus {
                &::before {
                    width: 100%;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        display: none;
        @include absolute($top: 0, $left: 100%, $right: inherit);
        z-index: inherit;
        width: 50vw;
        padding: 0 50px;
        background-color: $color-bg--transparent;
        box-shadow: none;

        &::after {
            content: "";
            @include absolute($top: 0, $left: -1px);
            @include size(1px, 100%);
            background-color: $color-second;
        }

        &__nav {
            @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
            gap: 5px 30px;
            max-width: inherit;
            max-height: 80vh;
            padding: 0;
        }

        &__item {
            width: auto;
            margin: 0;         
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            @include size(auto);
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 15px 0;
            border-bottom: 1px solid $color-second--rgba;
            color: $color-white;
            font-weight: $font-weight-medium;

            &--child {
                &::before {
                    content: '';
                    display: inherit;
                    @include absolute($bottom: 0);
                    @include size(0, 1px);
                    background-color: $color-white;
                    transition: width $duration $timing;
                }

                &:hover, &:focus {
                    &::before {
                        width: 100%;
                    }
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;
            }
        }
    }

}

// Button accessibility
button.avoid-main-content {
    @include absolute($bottom: 40px, $right: 40px);
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For no scroll on body when menu is open
body.js-open-menu {
    overflow: hidden;
}


//=================================================================================================
// Header only for burger menu on demosaisie
//=================================================================================================
// Tools
.header .tools--burger {
    top: auto;
}

// Identity
.identity.identity--burger {
    position: relative;
    z-index: 11;
    width: fit-content;
    height: auto;
    max-width: 85%;
    min-height: 90px;

    .identity__title {
        transition: {
            property: margin-top, color;
            duration: $duration;
            timing-function: $timing;
        }
    }
}

body.js-open-menu {
    .identity__title {
        margin-top: 30px;
        color: $color-white;
    }

    .quick-access {
        right: -960px;
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main--burger {

        // Main menu
        .menu {
            width: 30%;
            margin-top: 150px;
            padding: 0 30px 0 0;
        }

        // Sub menu
        .submenu {
            width: 65vw;
            padding: 0 30px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {
    
    // Header -- Identity
    .identity.identity--burger {
        position: inherit;
        z-index: inherit;
        max-width: 100%;
        height: auto;
    }
    body.js-open-menu .identity__title {
        margin-top: 0;
        color: $color-text;
    }

    // Menu
    .nav-main--burger {
        position: initial;
        height: auto;

        // if Burger menu open
        &.js-open-menu {

            // Burger menu Button
            .nav-main-buttons__menu { 
                @include absolute($bottom: 0, $right: 0, $top: 0);
                @include flex($alignItems: center, $justifyContent: center);
                @include size(45px);
                min-height: inherit;
                margin: 40px 40px 0 auto;
                padding: 0;
                border-radius: $border-radius--round;
                border: $btn-border-width $btn-border-style $color-white;
        
                &::after {
                    display: none;
                }
            }

            // Popup menu
            .nav-main--burger__popup {
                left: 0;
                transition: left $duration $timing;
            }

            // And If submenu open
            .nav-main-buttons.js-open-submenu {
                .nav-main-buttons__submenu {
                    left: 0;
                }
            }
        }

        // If submenu open
        &.js-open-submenu {
            .nav-main--burger__section {
                overflow: hidden;
            }
        }

        // Burger Buttons
        .nav-main-buttons {
            position: inherit;
            @include flex($alignItems: center, $justifyContent: flex-start);
            height: 69px;

            // Burger menu Button
            &__menu {
                @include relative($top: auto, $right: auto);
                width: fit-content;
                padding: 20px 0;
                transition: none;

                // Burger menu Button icon
                &__icon, 
                &__icon::before, 
                &__icon::after {
                    transition: none;
                }   
            }

            // Burger menu responsive - Close submenu
            &__submenu {
                @include absolute($top: 0, $left: -100vw);
                z-index: 11;
                @include flex($alignItems: center, $justifyContent: center);
                flex-shrink: 0;
                @include size(45px);
                margin: 40px auto 0 40px;
                padding: 0;
                background-color: $color-bg--transparent;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;
                transition: left $duration $timing;

                svg {
                    @include size(40px);
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                }
            }
        }

        // Popup menu
        &__popup {
            top: 0;
            right: inherit;
            left: -100vw;
            padding: 0;

            &::before {
                height: 100px;
            }
        }

        // Section menu
        &__section {
            padding: 0;
        }

        // Container menu
        &__container {
            max-width: initial;
            margin: 0;
            padding: 0;
        }

        // Main menu
        .menu {
            flex-wrap: nowrap;
            @include size(100%, auto);
            min-height: 100vh;
            margin: 0;
            padding: 100px 40px 40px;
            gap: 0;
            overflow: hidden;

            &::after {
                display: none;
            }

            &__item {
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                    }

                    &:focus-within .submenu,
                    &:hover .submenu {
                        display: flex;
                    }

                    &.js-open-submenu {
                        .submenu {   
                            left: 0;
                            display: flex;
                        }
                    }

                    .menu__link::after {
                        display: none;
                    }
                }
            }

            &__link {
                &::before {
                    display: none;
                }
            }
        }

        // Sub menu
        .submenu {
            @include fixed($top: 0, $right: inherit, $left: -100vw);
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 100px 0 0;
            background-color: $color-main;
            transition: left $duration $timing;

            &::after {
                display: none;
            }
    
            &__nav {
                @include flex($direction: column, $justifyContent: flex-start, $wrap: nowrap);
                gap: initial;
                @include size(100%, calc(100vh - 100px));
                max-width: 100%;
                max-height: inherit;
                overflow-y: auto;
                padding: 5px 40px 40px;  
            }

            &__item {
                width: 100%; 
            }

            &__link {
                width: 100%;

                &::before {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .menu, .submenu {
                padding-top: 132px;
            }

            &__popup::before {
                height: 132px;
            }
        }
    }
}


// 782 -- For admin bar only
@media screen and (max-width: 782px) {

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .menu, .submenu {
                padding-top: 146px;
            }
            
            &__popup::before {
                height: 146px;
            }
        }
    }

}
